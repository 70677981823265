import './tableTopicReport.css';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useRef, useEffect, useState } from 'react';

function TableTopicReport({ tableData, subSubject, headData, freezeIndex }) {
  const [columnPositions, setColumnPositions] = useState([]);
  const tableRef = useRef(null);
  // Calculate column positions dynamically
  useEffect(() => {
    if (tableRef.current) {
      const colWidths = Array.from(tableRef.current.querySelectorAll('thead th')).map((col) => col.offsetWidth);
      console.log(tableRef, 'asdmncxoasdnmo');
      const positions = colWidths.map((_, index) => colWidths.slice(0, index).reduce((acc, width) => acc + width, 0));
      setColumnPositions(positions);
    }
  }, [tableData]);
  console.log(tableRef, 'asdmncxoasdnmo');
  return (
    <div>
      <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'scroll' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" ref={tableRef}>
          <TableHead
            style={{
              position: '',
              top: '0',
              background: 'rgb(214, 212, 212)',
              color: 'white',
              fontWeight: '800',
            }}>
            <TableRow>
              {headData ? (
                <>
                  {headData?.map((item, index) => (
                    <TableCell
                      className="sticky-column"
                      align={item?.position}
                      style={{
                        position: 'sticky',
                        top: 0,
                        left: index < freezeIndex ? `${columnPositions[index] || 0}px` : undefined,
                        background: index < freezeIndex ? '#e1dcdc' : 'rgb(214, 212, 212)', // For fixed columns
                        zIndex: index < freezeIndex ? 2 : 1, // Higher z-index for sticky cells
                      }}>
                      {item?.name}
                    </TableCell>
                  ))}
                </>
              ) : (
                <>
                  <TableCell>Sl. No.</TableCell>
                  <TableCell align="left">Course</TableCell>
                  <TableCell align="left">Subject</TableCell>
                  <TableCell align="left">Topic</TableCell>
                  <TableCell align="left">No. Of Questions</TableCell>

                  <TableCell align="left">DL1</TableCell>
                  <TableCell align="left">DL2</TableCell>
                  <TableCell align="left">DL3</TableCell>
                  <TableCell align="left">DL4</TableCell>
                  <TableCell align="left">DL5</TableCell>
                  <TableCell align="left">DL6</TableCell>
                  <TableCell align="left">DL7</TableCell>
                  <TableCell align="left">DL8</TableCell>
                  <TableCell align="left">DL9</TableCell>
                  <TableCell align="left">DL10</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody className="TableBody">
            {tableData?.map((row, index) => (
              <TableRow
                key={row.index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}>
                {headData ? (
                  <>
                    {headData?.map((i, colIndex) => (
                      <TableCell
                        align="center"
                        sx={{ padding: '8px 4px', fontSize: '14px' }}
                        component="td"
                        scope="row"
                        style={{
                          position: colIndex < freezeIndex ? 'sticky' : 'static',
                          left: colIndex < freezeIndex ? `${columnPositions[colIndex] || 0}px` : undefined,
                          background: colIndex < freezeIndex ? '#fff' : undefined,
                          zIndex: colIndex < freezeIndex ? 1 : 0,
                        }}>
                        {i.mapped === 'index' ? index + 1 : row?.[i.mapped] === '0' ? '-' : row?.[i.mapped]}
                      </TableCell>
                    ))}
                  </>
                ) : (
                  <>
                    <TableCell align="center" sx={{ padding: '8px 4px', fontSize: '14px' }} component="td" scope="row">
                      {row?.s_no || index + 1}
                    </TableCell>
                    <TableCell sx={{ padding: '8px 4px', fontSize: '14px' }} align="left">
                      {row.course_name}
                    </TableCell>
                    <TableCell sx={{ padding: '8px 4px', fontSize: '14px' }} align="left">
                      {row.subject_name}
                    </TableCell>
                    <TableCell sx={{ padding: '8px 4px', fontSize: '14px' }} align="left">
                      {row.topic_name}
                    </TableCell>
                    <TableCell sx={{ padding: '8px 4px', fontSize: '14px' }} align="center">
                      {row.no_of_questions}
                    </TableCell>
                    <TableCell sx={{ padding: '8px 4px', fontSize: '14px' }} align="center">
                      {+row.difficulty_level_1 === 0 ? '-' : row.difficulty_level_1}
                    </TableCell>
                    <TableCell sx={{ padding: '8px 4px', fontSize: '14px' }} align="center">
                      {+row.difficulty_level_2 === 0 ? '-' : row.difficulty_level_2}
                    </TableCell>
                    <TableCell sx={{ padding: '8px 4px', fontSize: '14px' }} align="center">
                      {+row.difficulty_level_3 === 0 ? '-' : row.difficulty_level_3}
                    </TableCell>
                    <TableCell sx={{ padding: '8px 4px', fontSize: '14px' }} align="center">
                      {+row.difficulty_level_4 === 0 ? '-' : row.difficulty_level_4}
                    </TableCell>
                    <TableCell sx={{ padding: '8px 4px', fontSize: '14px' }} align="center">
                      {+row.difficulty_level_5 === 0 ? '-' : row.difficulty_level_5}
                    </TableCell>
                    <TableCell sx={{ padding: '8px 4px', fontSize: '14px' }} align="center">
                      {+row.difficulty_level_6 === 0 ? '-' : row.difficulty_level_6}
                    </TableCell>
                    <TableCell sx={{ padding: '8px 4px', fontSize: '14px' }} align="center">
                      {+row.difficulty_level_7 === 0 ? '-' : row.difficulty_level_7}
                    </TableCell>
                    <TableCell sx={{ padding: '8px 4px', fontSize: '14px' }} align="center">
                      {+row.difficulty_level_8 === 0 ? '-' : row.difficulty_level_8}
                    </TableCell>
                    <TableCell sx={{ padding: '8px 4px', fontSize: '14px' }} align="center">
                      {+row.difficulty_level_9 === 0 ? '-' : row.difficulty_level_9}
                    </TableCell>
                    <TableCell sx={{ padding: '8px 4px', fontSize: '14px' }} align="center">
                      {+row.difficulty_level_10 === 0 ? '-' : row.difficulty_level_10}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <table>
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th className="topicReportThCourse">Course</th>
            <th className="topicReportThSubject">Subject</th>
            {subSubject ? (
              <th className="topicReportThSubject">Sub-Subject</th>
            ) : null}
            <th className="topicReportThTopic">Topic</th>
            <th>No. Of Questions</th>
            {Array.apply(null, { length: 10 }).map((e, keyDiff) => {
              return <th key={keyDiff}>D. L {keyDiff + 1}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, tableKey) => {
            return (
              <tr key={tableKey}>
                <td>{tableKey + 1}</td>
                <td>{row.course_name}</td>
                <td>{row.subject_name}</td>
                {subSubject ? <td>{row.sub_subject_name}</td> : null}
                <td>{row.topic_name}</td>
                <td>{row.no_of_questions}</td>
                {Array.apply(null, { length: 10 }).map((e, bodyDiff) => {
                  return (
                    <td key={bodyDiff}>
                      {row[`difficulty_level_${bodyDiff + 1}`] === 0 ||
                      row[`difficulty_level_${bodyDiff + 1}`] === "0"
                        ? "-"
                        : row[`difficulty_level_${bodyDiff + 1}`]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table> */}
    </div>
  );
}

export default TableTopicReport;
