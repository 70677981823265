import React, { useState, useEffect, useRef } from 'react';
import './qSetReport.css';
// import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver';
import Pagination from '../../SubComponents/Pagination/Pagination';
// import TableQSetReport from './QSetReportComponents/TableQSetReport/TableQSetReport';
import QSetReportSearch from './QSetReportComponents/QSetReportSearch/QSetReportSearch';
import { fetchOtherResultsQSetReport } from './QSetReportFunction/fetchOtherResultsQSetReport';
import { fetchDataQSetReport } from './QSetReportFunction/fetchDataQSetReport';
import { qSetReportSubmit } from './QSetReportFunction/qSetReportSubmit';
import { fetchQSetReport } from '../../../api/report';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ImageAsset from '../../../assets/images/imageAsset';
import TableTopicReport from '../TopicReport/TopicReportComponents/TableTopicReport/TableTopicReport';

function QSetReport() {
  const [formData, setFormData] = useState({
    question_type_id: -1,
    board_id: -1,
    course_id: -1,
    subject_id: -1,
    sub_subject_id: -1,
    topic_id: -1,
    medium_id: -1,
    from_date: '',
    to_date: '',
  });
  const [state, setState] = useState({
    questionsType: [],
    medium: [],
    board: [],
    course: [],
    subject: [],
    subSubject: [],
    topic: [],
  });
  const [qSetReportList, setQSetReportList] = useState([]);
  // const [questionView, setQuestionView] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [startUpLoad, setStartUpLoad] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [numberOfQSetReports, setNumberOfQSetReports] = useState(0);
  const [noData, setNoData] = useState(false);
  const initialRender = useRef(true);

  // const userId = window.localStorage.getItem("userId");
  var institution_id = 0;
  const limit = 10;
  console.log(formData);

  function handleChange(e, selLabel) {
    const valueSele = e?.target?.value || e?.value || e;
    fetchOtherResultsQSetReport(e, selLabel, institution_id, setFormData, setStartUpLoad, state, setState, formData);
    setFormData((prevState) => ({
      ...prevState,
      [selLabel]: valueSele,
    }));
  }

  function paginationClick(number, offSetNumber) {
    if (Math.ceil(numberOfQSetReports / 10) > 1) {
      initialRender.current = false;
      setPageNumber(+number);
      setOffSet(+offSetNumber);
      console.log(offSet, pageNumber, 'jhvb');
      // setStartUpLoad(true);
    }
  }

  useEffect(() => {
    setStartUpLoad(true);

    fetchDataQSetReport(setState, state, setStartUpLoad);
    // setFormData((prevState) => {
    //   return { ...prevState, added_by_user_id: userId };
    // });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log('hjjbiub', initialRender);
    if (initialRender.current === false) {
      console.log('hjjbiub');
      async function handlePagination() {
        console.log(offSet);
        qSetReportSubmit(
          setSubmitLoading,
          setNoData,
          formData,
          setNumberOfQSetReports,
          setQSetReportList,
          limit,
          offSet,
          setStartUpLoad,
        );
        initialRender.current = true;
      }
      handlePagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offSet]);

  const createDownloadInfo = (formData) => {
    const link = [];
    if (formData.medium_id && formData.medium_id !== -1) {
      link.push(`medium_id=${formData.medium_id}`);
    }
    if (formData.board_id && formData.board_id !== -1) {
      link.push(`board_id=${formData.board_id}`);
    }
    if (formData.course_id && formData.course_id !== -1) {
      link.push(`course_id=${formData.course_id}`);
    }
    if (formData.subject_id && formData.subject_id !== -1) {
      link.push(`subject_id=${formData.subject_id}`);
    }
    if (formData.sub_subject) {
      link.push(`sub_subject_id=${formData.sub_subject}`);
    }
    if (formData.topic_id && formData.topic_id !== -1) {
      link.push(`topic_id=${formData.topic_id}`);
    }
    if (formData.from_date) {
      link.push(`from_date=${formData.from_date}`);
    }
    if (formData.to_date) {
      link.push(`to_date=${formData.to_date}`);
    }

    const linkFinal = link.join('&');
    return linkFinal;
  };

  const handleDownload = async () => {
    setStartUpLoad(true);
    const excelDownloadParams = createDownloadInfo(formData);
    // console.log("excelDownloadParams", excelDownloadParams);
    fetchQSetReport(excelDownloadParams)
      .then((res) => {
        const excelData = res;
        const fileName = excelData?.headers[`content-disposition`].split('=')[1];
        console.log('fileName', fileName);
        saveAs(excelData.data, fileName);
        toast.success('file downloaded');
        setStartUpLoad(false);
      })
      .catch((err) => {
        console.log('err', err);
        toast.error(err?.message);
        setStartUpLoad(false);
      });
  };
  const tableHead = [
    {
      name: 'Sl. No.',
      position: 'center',
      mapped: 'index',
    },
    {
      name: 'Course',
      position: 'center',
      mapped: 'course_name',
    },
    {
      name: 'Medium',
      position: 'center',
      mapped: 'medium',
    },
    {
      name: 'Subject',
      position: 'center',
      mapped: 'subject_name',
    },
    {
      name: 'Sub-Subject',
      position: 'center',
      mapped: 'sub_subject_name',
    },
    {
      name: 'Topic',
      position: 'center',
      mapped: 'topic_name',
    },
    {
      name: 'No. Of QSet',
      position: 'center',
      mapped: 'question_count',
    },
    {
      name: 'Question Count',
      position: 'center',
      mapped: 'no_of_qset',
    },
  ];

  return (
    <div>
      {startUpLoad ? (
        <div>
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={startUpLoad}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      ) : null}
      <div style={{ display: 'flex', height: 'calc(100vh - 64px)' }}>
        <QSetReportSearch
          state={state}
          formData={formData}
          handleChange={handleChange}
          submitLoading={submitLoading}
          noData={noData}
          setSubmitLoading={setSubmitLoading}
          setNoData={setNoData}
          setNumberOfQSetReports={setNumberOfQSetReports}
          setQSetReportList={setQSetReportList}
          limit={limit}
          offSet={offSet}
          setStartUpLoad={setStartUpLoad}
          createDownloadInfo={createDownloadInfo}
          setPageNumber={setPageNumber}
        />

        {noData ? (
          <div className="noData">
            There is no available data.
            <div>
              <img src={ImageAsset?.qb_nodata?.default} alt="" />
            </div>
          </div>
        ) : null}
        {qSetReportList.length !== 0 ? (
          <div className="qSetReportTableContainer" style={{ width: '78%' }}>
            <button className="download-report-btn" onClick={handleDownload}>
              Download
            </button>

            <div>
              {/* <TableQSetReport tableData={qSetReportList} /> */}
              <TableTopicReport tableData={qSetReportList} headData={tableHead} />
            </div>
            <div style={{ border: ' 1px solid rgba(224, 224, 224, 1)' }}>
              <Pagination
                paginationClick={paginationClick}
                numberOfRows={numberOfQSetReports}
                offSet={offSet}
                pageNumber={pageNumber}
              />
            </div>
          </div>
        ) : (
          <>
            {!noData ? (
              <div className="noData" style={{ textTransform: 'capitalize' }}>
                Please use left panel to search question
                <div>
                  <img src={ImageAsset?.qb_uaeleftimage?.default} alt="" />
                </div>
              </div>
            ) : null}
          </>
        )}
        <ToastContainer
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
}

export default QSetReport;
