import { fetchParticularQuestion } from '../../../../api/questions';
import errorHandle from '../../../../utils/errorHandle/errorHandle';
import fetchResults from './fetchResults';

export default async function addFormData(
  question_id,
  setFormData,
  questionFileId,
  assigneeBoard,
  assigneeOptions,
  institution_id,
  setStartUpLoad,
  setState,
  setPrintURL,
  solutionChg,
  setResponseMCQ,
  setcuratorDetails,
  curatorDetails,
) {
  const previousImageLink = window.localStorage.getItem('previousImageLink');
  const questionReuseId = +window.localStorage.getItem('reuseId');
  const role = window.localStorage.getItem('role');
  try {
    var idFormData = await fetchParticularQuestion(question_id);
    console.log(idFormData.data);
    console.log(idFormData.data.data[0], 'qqqqq');
    if (idFormData.data.data[0] !== undefined && idFormData?.data?.data[0] !== {}) {
      setResponseMCQ(idFormData.data.data[0]?.answer_json);
      setFormData((prevState) => {
        return {
          ...prevState,
          question_file_id: questionFileId,
          copiable_data_file_path: idFormData.data.data[0].copiable_data_file_path,
          reference: idFormData.data.data[0].reference,
          question_type_id: idFormData.data.data[0].question_type_id,
          board_id: idFormData.data.data[0].board_id,
          course_id: idFormData.data.data[0].course_id,
          subject_id: idFormData.data.data[0].subject_id,
          sub_subject_id: idFormData.data.data[0].sub_subject_id,
          topic_1_id: idFormData.data.data[0].topic_1_id,
          sub_topic_1_id: idFormData.data.data[0].sub_topic_1_id,
          topic_2_id: idFormData.data.data[0].topic_2_id,
          sub_topic_2_id: idFormData.data.data[0].sub_topic_2_id,
          difficulty_level: idFormData.data.data[0].difficulty_level,
          answer_input_type_id: idFormData.data.data[0].answer_input_type_id,
          response_type_id: idFormData.data.data[0].response_type_id,
          evaluation_type_id: idFormData.data.data[0].evaluation_type_id,
          access_level_id: idFormData.data.data[0].access_level_id,
          taxonomy_id: idFormData.data.data[0].taxonomy_id,
          objective_id: idFormData.data.data[0].objective_id,
          max_marks: idFormData.data.data[0].max_marks,
          time_required: idFormData.data.data[0].time_required,
          medium_id: idFormData.data.data[0].medium_id,
          short_description: idFormData.data.data[0].short_description,
          description: idFormData.data.data[0].description,
          hint: idFormData.data.data[0].hint,
          solution_text: idFormData.data.data[0].solution_text,
          is_enabled: idFormData.data.data[0].is_enabled,
          answer_json: idFormData.data.data[0].answer_json,
          solution_file: idFormData.data.data[0].solution_file,
          image_path: previousImageLink,
          added_by_user_id: idFormData.data.data[0].added_by_user_id,
          question_reuse_id:
            questionReuseId === null || questionReuseId === undefined
              ? +idFormData.data.data[0].question_reuse_id
              : +questionReuseId,
        };
      });
      setcuratorDetails({
        ...curatorDetails,
        preCuratorId: idFormData.data.data[0].curated_by_user_id,
        preCuratorName: idFormData.data.data[0].curated_by_user_name,
      });
      setPrintURL(idFormData.data.data[0].copiable_data_file_path);
      solutionChg(idFormData.data.data[0].solution_file);
      console.log(role);
      // console.log("formdata", formData);
      console.log('questionFIleID', questionFileId);
      assigneeBoard(idFormData.data.data[0], role);
      let board_id = +idFormData.data.data[0].board_id || -1;
      let course_id = +idFormData.data.data[0].course_id || -1;
      let subject_id = +idFormData.data.data[0].subject_id || -1;
      let sub_subject_id = +idFormData.data.data[0].sub_subject_id || -1;
      let topic_1_id = +idFormData.data.data[0].topic_1_id || -1;
      let topic_2_id = +idFormData.data.data[0].topic_2_id || -1;
      let taxonomy_id = +idFormData.data.data[0].taxonomy_id || -1;

      assigneeOptions(idFormData.data.data[0]);

      fetchResults(
        institution_id,
        setStartUpLoad,
        setState,
        board_id,
        course_id,
        subject_id,
        sub_subject_id,
        topic_1_id,
        topic_2_id,
        taxonomy_id,
      );
    }
  } catch (err) {
    console.log(err);
    errorHandle(err);
    setStartUpLoad(false);
  }
}
