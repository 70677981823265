/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import Dropdown from '../../SubComponents/Dropdown/Dropdown';
import DropdownQSet from '../../SubComponents/DropdownQSet/DropdownQSet';
import { BASE_URL } from '../../config/Api';
import Axios from 'axios';
// import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
// import Pagination from "../../SubComponents/Pagination/Pagination";
import PublicIcon from '@mui/icons-material/Public';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';
import './qsetCreator.css';
// import errimg from '../../../assets/images/errimage.svg';
import Checkbox from '@mui/material/Checkbox';
import { TopicTable, SubTopicTable, DifficultyLevelGraph, Objective } from '../../SubComponents/SummaryTable/SummaryTable';
import { createQuestionSet, deleteQuestionFromSet, questionsForQSet, updateSetMetadata } from '../../../api/questions';
import { fetchAccessLevel, fetchQuestionType } from '../../../api/qbMasterData';
import { boardList } from '../../../api/boards';
import { fetchCourse, fetchSubject, fetchSubSubject, fetchSubTopic, fetchTopic } from '../../../api/institution';
import { fetchMedium } from '../../../api/mediums';
import { useLocation } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import ImageAsset from '../../../assets/images/imageAsset';
import SelectInput from '../../SubComponents/SelectInput/SelectInput';
import { useForm } from 'react-hook-form';

const difficultyLevel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
var institution_id = 0;
var question_type_id = -1;
var board_id = -1;
var course_id = -1;
var subject_id = -1;
var sub_subject_id = -1;
var topic_1_id = -1;
var link;
var linkFinal;
var unCheckedRowIndex;
var unCheckedRowIndexRemove;
var questionPresent = [];
var questionAddedArray = [];
var questionDeletedArray = [];
var isEdit = false;
var questionSetIdEdit = -1;
var editQuestionSetDetails;
var qSetBoard_id = -1;
var qSetCourse_id = -1;
var qSetSubject_id = -1;
var qSetSub_subject_id = -1;
var QSetTotalMarks = 0;

function QSetCreator() {
  const location = useLocation();
  console.log(location, 'location');
  const [value, setValues] = useState(0);
  const [qSelectTab, setqSelectTab] = useState(0);

  const [formData, setFormData] = useState({
    question_type_id: '',
    board_id: '',
    course_id: '',
    subject_id: '',
    sub_subject_id: '',
    topic_1_id: '',
    sub_topic_1_id: '',
    difficulty_level: '',
    access_level_id: '',
    medium_id: '',
    question_code: '',
    added_by_user_id: 0,
  });
  const [state, setState] = useState({
    questionsType: [],
    medium: [],
    board: [],
    course: [],
    subject: [],
    subSubject: [],
    topic: [],
    subTopic: [],
    subTopic2: [],
    accessLevel: [],
  });
  const [startUpLoad, setStartUpLoad] = useState(true);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [offSet, setOffSet] = useState(0);
  // const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [noData, setNoData] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [questionView, setQuestionView] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selLink, setSelLink] = useState('');
  const [selData, setSelData] = useState();
  const [checkedRows, setCheckedRows] = useState([]);
  const [setSaved, setSetSaved] = useState(false);
  const [metadata, setMetadata] = useState({
    display_name: '',
    description: '',
    board_id: '',
    board: '',
    course_id: '',
    course: '',
    subject_id: '',
    subject: '',
    sub_subject_id: '',
    sub_subject: '',
    topic_id: '',
    topic: '',
  });
  const [qSetState, setQSetState] = useState({
    board: [],
    course: [],
    subject: [],
    subSubject: [],
    topic: [],
  });
  const [setCode, setSetCode] = useState('');

  useEffect(() => {
    if (isEdit) {
      setValues(1);
    }
  }, [location.pathname]);
  console.log(isEdit, question_type_id, 'edit');
  // console.log(setCode, metadata);

  const token = window.localStorage.getItem('token');
  const userId = window.localStorage.getItem('userId');
  const Auth = {
    headers: {
      authorization: 'Bearer ' + token,
    },
  };

  const {
    setValue,
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    register('question_type_id', { required: true });
    register('board_id', { required: true });
    register('course_id', { required: true });
    register('subject_id', { required: true });
  }, [register]);

  function handleChange(e, selLabel) {
    // const valueSele = e?.target?.value || e;
    fetchOtherResults(e, selLabel);

    // setFormData({
    //   ...formData,
    //   [selLabel]: valueSele,
    // });
  }
  const TotalMarks = (e) => {
    let totalmarks = 0;
    if (e.length > 0) {
      for (let j = 0; j < e.length; j++) {
        totalmarks += Number(e[j].max_marks);
      }
    }
    return totalmarks;
  };

  function handleQSetChange(e, selLabel) {
    const valueSele = e?.target?.value || e;
    if (selLabel === 'topic_1_id') {
      setMetadata((prevState) => {
        return { ...prevState, topic_id: valueSele };
      });
    } else {
      setMetadata((prevState) => {
        return { ...prevState, [selLabel]: valueSele };
      });
      fetchOtherQSetResults(e, selLabel);
    }
  }

  async function fetchOtherQSetResults(e, selLabel) {
    if (selLabel === 'board_id') {
      setMetadata((prevState) => {
        return {
          ...prevState,
          course_id: -1,
          subject_id: -1,
          sub_subject_id: -1,
          topic_id: -1,
        };
      });
      setStartUpLoad(true);
      qSetBoard_id = e?.target?.value || e;
      qSetCourse_id = -1;
      qSetSubject_id = -1;
      qSetSub_subject_id = -1;
      try {
        // const getQSetCourse = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${qSetBoard_id}/courses`,
        //   Auth
        // );
        const getQSetCourse = await fetchCourse(institution_id, qSetBoard_id);
        setQSetState((prevState) => {
          return {
            ...prevState,
            course: getQSetCourse.data.data,
          };
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === 'course_id') {
      setMetadata((prevState) => {
        return {
          ...prevState,
          subject_id: -1,
          sub_subject_id: -1,
          topic_id: -1,
        };
      });
      setStartUpLoad(true);
      qSetCourse_id = e?.target?.value || e;
      qSetSubject_id = -1;
      qSetSub_subject_id = -1;
      try {
        // const getQSetSubject = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${qSetBoard_id}/courses/${qSetCourse_id}/subjects`,
        //   Auth
        // );
        const getQSetSubject = await fetchSubject(institution_id, qSetBoard_id, qSetCourse_id);
        setQSetState((prevState) => {
          return {
            ...prevState,
            subject: getQSetSubject.data.data,
          };
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === 'subject_id') {
      setMetadata((prevState) => {
        return { ...prevState, sub_subject_id: -1, topic_id: -1 };
      });
      setStartUpLoad(true);
      qSetSubject_id = e?.target?.value || e;
      qSetSub_subject_id = -1;
      try {
        // const getQSetSubSubject = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${qSetBoard_id}/courses/${qSetCourse_id}/subjects/${qSetSubject_id}/sub_subjects`,
        //   Auth
        // );
        const getQSetSubSubject = await fetchSubSubject(institution_id, qSetBoard_id, qSetCourse_id, qSetSubject_id);
        setQSetState((prevState) => ({
          ...prevState,
          subSubject: getQSetSubSubject.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        console.log(err, 'error while fetching data');
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === 'sub_subject_id') {
      setMetadata((prevState) => {
        return { ...prevState, topic_id: -1 };
      });
      setStartUpLoad(true);
      qSetSub_subject_id = e?.target?.value || e;
      try {
        // const getQSetTopic = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${qSetBoard_id}/courses/${qSetCourse_id}/subjects/${qSetSubject_id}/sub_subjects/${qSetSub_subject_id}/topics`,
        //   Auth
        // );
        const getQSetTopic = await fetchTopic(institution_id, qSetBoard_id, qSetCourse_id, qSetSubject_id, qSetSub_subject_id);
        setQSetState((prevState) => ({
          ...prevState,
          topic: getQSetTopic.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        console.log(err, 'error while fetching data');
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
  }

  useEffect(() => {
    setStartUpLoad(true);
    async function fetchData() {
      try {
        // let getQuestionType = await Axios.get(
        //   `${BASE_URL}/qb/question_types`,
        //   Auth
        // );
        const getQuestionType = await fetchQuestionType();
        // const getAccessLevel = await Axios.get(
        //   `${BASE_URL}/qb/access_levels`,
        //   Auth
        // );
        const getAccessLevel = await fetchAccessLevel();
        // const getMediums = await Axios.get(`${BASE_URL}/mediums`, Auth);
        const getMediums = await fetchMedium();
        setState({
          ...state,
          questionsType: getQuestionType.data.data?.map((i) => ({
            label: i?.display_name,
            value: i?.id?.toString(),
          })),
          accessLevel: getAccessLevel.data.data?.map((i) => ({
            label: i?.display_name,
            value: i?.id?.toString(),
          })),
          medium: getMediums.data.data?.map((i) => ({
            label: i?.medium_name,
            value: i?.medium_id?.toString(),
          })),
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    fetchData();
    setFormData((prevState) => {
      return { ...prevState, added_by_user_id: userId };
    });
    // eslint-disable-next-line
  }, []);

  async function fetchOtherResults(e, selLabel) {
    if (selLabel === 'question_type_id') {
      setValue('board_id', '');
      setValue('course_id', '');
      setValue('subject_id', '');

      question_type_id = e?.target?.value || e;
      board_id = -1;
      course_id = -1;
      subject_id = -1;
      sub_subject_id = -1;
      topic_1_id = -1;
      setFormData({
        ...formData,
        question_type_id: e?.target?.value || e,
        board_id: '',
        course_id: '',
        subject_id: '',
        sub_subject_id: '',
        topic_1_id: '',
        sub_topic_1_id: '',
        access_level_id: '',
        medium_id: '',
      });
      setStartUpLoad(true);

      try {
        // const getBoard = await Axios.get(`${BASE_URL}/boards`, Auth);
        const getBoard = await boardList();
        setState({
          ...state,
          board: getBoard.data.data?.map((i) => ({
            label: i?.board_display_name,
            value: i?.board_id?.toString(),
          })),
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === 'board_id') {
      setStartUpLoad(true);
      setValue('course_id', '');
      setValue('subject_id', '');
      board_id = e?.target?.value || e;
      course_id = -1;
      subject_id = -1;
      sub_subject_id = -1;
      topic_1_id = -1;
      setFormData({
        ...formData,
        board_id: e?.target?.value || e,
        course_id: '',
        subject_id: '',
        sub_subject_id: '',
        topic_1_id: '',
        sub_topic_1_id: '',
        access_level_id: '',
        medium_id: '',
      });
      try {
        // const getCourse = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses`,
        //   Auth
        // );
        const getCourse = await fetchCourse(institution_id, board_id);
        setState({
          ...state,
          course: getCourse.data.data?.map((i) => ({
            label: i?.display_name,
            value: i?.course_id?.toString(),
          })),
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === 'course_id') {
      setValue('subject_id', '');
      setFormData((prevState) => {
        return {
          ...prevState,
          course_id: e?.target?.value || e,
          subject_id: '',
          sub_subject_id: '',
          topic_1_id: '',
          sub_topic_1_id: '',
          access_level_id: '',
          medium_id: '',
        };
      });
      setStartUpLoad(true);
      course_id = e?.target?.value || e;
      subject_id = -1;
      sub_subject_id = -1;
      topic_1_id = -1;
      try {
        // const getSubject = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects`,
        //   Auth
        // );
        const getSubject = await fetchSubject(institution_id, board_id, course_id);
        setState({
          ...state,
          subject: getSubject.data.data?.map((i) => ({
            label: i?.display_name,
            value: i?.subject_id?.toString(),
          })),
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === 'subject_id') {
      setFormData((prevState) => {
        return {
          ...prevState,
          subject_id: e?.target?.value || e,
          sub_subject_id: '',
          topic_1_id: '',
          sub_topic_1_id: '',
          access_level_id: '',
          medium_id: '',
        };
      });
      setStartUpLoad(true);
      subject_id = e?.target?.value || e;
      sub_subject_id = -1;
      topic_1_id = -1;
      try {
        // const getSubSubject = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects`,
        //   Auth
        // );
        const getSubSubject = await fetchSubSubject(institution_id, board_id, course_id, subject_id);
        setState((prevState) => ({
          ...prevState,
          subSubject: getSubSubject.data.data?.map((i) => ({
            label: i?.display_name,
            value: i?.sub_subject_id?.toString(),
          })),
        }));
        setStartUpLoad(false);
      } catch (err) {
        console.log(err, 'error while fetching data');
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === 'sub_subject_id') {
      setFormData((prevState) => {
        return {
          ...prevState,
          sub_subject_id: e?.target?.value || e,
          topic_1_id: '',
          sub_topic_1_id: '',
          access_level_id: '',
          medium_id: '',
        };
      });
      setStartUpLoad(true);
      sub_subject_id = e?.target?.value || e;
      topic_1_id = -1;
      try {
        // const getTopic = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/${sub_subject_id}/topics`,
        //   Auth
        // );
        const getTopic = await fetchTopic(institution_id, board_id, course_id, subject_id, sub_subject_id);
        setState((prevState) => ({
          ...prevState,
          topic: getTopic.data.data?.map((i) => ({
            label: i?.display_name,
            value: i?.topic_id?.toString(),
          })),
        }));
        setStartUpLoad(false);
      } catch (err) {
        console.log(err, 'error while fetching data');
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === 'topic_1_id') {
      setFormData((prevState) => {
        return { ...prevState, topic_1_id: e?.target?.value || e, sub_topic_1_id: '', access_level_id: '', medium_id: '' };
      });
      setStartUpLoad(true);
      topic_1_id = e?.target?.value || e;
      try {
        // const getSubTopic = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/${sub_subject_id}/topics/${topic_1_id}/sub_topics`,
        //   Auth
        // );
        const getSubTopic = await fetchSubTopic(institution_id, board_id, course_id, subject_id, sub_subject_id, topic_1_id);
        console.log(getSubTopic.data.data);
        setState((prevState) => ({
          ...prevState,
          subTopic: getSubTopic.data.data?.map((i) => ({
            label: i?.display_name,
            value: i?.sub_topic_id?.toString(),
          })),
        }));
        setStartUpLoad(false);
      } catch (err) {
        console.log(err, 'error while fetching data');
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === 'sub_topic_1_id') {
      setFormData((prevState) => {
        return { ...prevState, sub_topic_1_id: e?.target?.value || e, access_level_id: '', medium_id: '' };
      });
      setStartUpLoad(true);
      // sub_topic_1_id = e?.target?.value || e;
      try {
        // const getAccessLevel = await Axios.get(
        //   `${BASE_URL}/qb/access_levels`,
        //   Auth
        // );
        const getAccessLevel = await fetchAccessLevel();
        setState({
          ...state,
          accessLevel: getAccessLevel.data.data?.map((i) => ({
            label: i?.display_name,
            value: i?.id?.toString(),
          })),
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === 'difficulty_level') {
      setFormData((prevState) => {
        return { ...prevState, difficulty_level: e?.target?.value || e };
      });
    }
    if (selLabel === 'access_level_id') {
      setFormData((prevState) => {
        return { ...prevState, access_level_id: e?.target?.value || e, medium_id: '' };
      });
    }
    if (selLabel === 'medium_id') {
      setFormData((prevState) => {
        return { ...prevState, medium_id: e?.target?.value || e };
      });
    }
  }

  //function to search questions
  async function Submit() {
    if (false) {
      toast.error('Please Select Options Till Subject');
    } else {
      setSubmitLoading(true);
      setNoData(false);
      setSetSaved(true);
      link = [];
      if (formData.question_type_id && +formData.question_type_id !== -1) {
        link.push(`question_type_id=${formData.question_type_id}`);
      }
      if (formData.board_id && +formData.board_id !== -1) {
        link.push(`board_id=${formData.board_id}`);
      }
      if (formData.course_id && +formData.course_id !== -1) {
        link.push(`course_id=${formData.course_id}`);
      }
      if (formData.subject_id && +formData.subject_id !== -1) {
        link.push(`subject_id=${formData.subject_id}`);
      }
      if (formData.sub_subject_id && +formData.sub_subject_id !== -1) {
        link.push(`sub_subject_id=${formData.sub_subject_id}`);
      }
      if (formData.topic_1_id && +formData.topic_1_id !== -1) {
        link.push(`topic_1_id=${formData.topic_1_id}`);
      }
      if (formData.sub_topic_1_id && +formData.sub_topic_1_id !== -1) {
        link.push(`sub_topic_1_id=${formData.sub_topic_1_id}`);
      }
      if (formData.topic_2_id && +formData.topic_2_id !== -1) {
        link.push(`topic_2_id=${formData.topic_2_id}`);
      }
      if (formData.sub_topic_2_id && +formData.sub_topic_2_id !== -1) {
        link.push(`sub_topic_2_id=${formData.sub_topic_2_id}`);
      }
      if (formData.difficulty_level && +formData.difficulty_level !== -1) {
        link.push(`difficulty_level=${formData.difficulty_level}`);
      }
      if (formData.access_level_id && +formData.access_level_id !== -1) {
        link.push(`access_level_id=${formData.access_level_id}`);
      }
      if (formData.medium_id && +formData.medium_id !== -1) {
        link.push(`medium_id=${formData.medium_id}`);
      }
      if (formData.added_by_user_id) {
        // link.push(`added_by_user_id=${formData.added_by_user_id}`);
        link.push(`user_id=${formData.added_by_user_id}`);
      }
      linkFinal = link.join('&');
      console.log(linkFinal);
      console.log(formData);
      const AuthSub = {
        headers: {
          authorization: 'Bearer ' + token,
        },
      };
      try {
        // const getNumberOfQuestions = await Axios.get(
        //   `${BASE_URL}/qb/questions/count_questions?${linkFinal}`,
        //   Auth
        // );
        // console.log(getNumberOfQuestions.data.data.count);
        const getQuestions = await Axios.get(
          // `${BASE_URL}/qb/questions/search?${linkFinal}&limit=10&offset=${offSet}`,
          `${BASE_URL}/qb/questions/search?${linkFinal}`,
          {
            headers: AuthSub.headers,
          },
        );
        console.log(getQuestions.data.data);
        if (isEdit) {
          var tempQuestionIdList = checkedRows.map((question) => {
            return +question.question_id;
          });
          console.log(tempQuestionIdList);
          var tempUncheckedList = getQuestions.data.data.filter((question) => {
            return !tempQuestionIdList.includes(+question.question_id);
          });
          console.log(tempUncheckedList);
          setQuestionList(tempUncheckedList);
          setQuestionView(true);
          setSubmitLoading(false);
          window.scrollTo(0, 600);
        } else {
          // setNumberOfQuestions(getNumberOfQuestions.data.data.count);
          setQuestionList(getQuestions.data.data);
          if (getQuestions.data.data.length > 0) {
            setQuestionView(true);
            setSubmitLoading(false);
            window.scrollTo(0, 600);
          } else {
            setQuestionView(false);
            setSubmitLoading(false);
            setNoData(true);
          }
        }
      } catch (err) {
        setNoData(true);
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setSubmitLoading(false);
      }
    }
  }

  // useEffect(() => {
  //   async function handlePagination() {
  //     console.log(offSet);
  //     const AuthSub1 = {
  //       headers: {
  //         authorization: "Bearer " + token,
  //       },
  //     };
  //     try {
  //       const getQuestions = await Axios.get(
  //         `${BASE_URL}/qb/questions/search?${linkFinal}&limit=10&offset=${offSet}`,
  //         {
  //           headers: AuthSub1.headers,
  //         }
  //       );

  //       console.log(getQuestions.data.data);
  //       setQuestionList(getQuestions.data.data);
  //       setStartUpLoad(false);
  //     } catch (err) {
  //       if (err.response && err.response.data) {
  //         console.log(err.response.data.message);
  //         toast.error(err.response.data.message);
  //       }else {
  // toast.error("Server Error");
  // }
  //       setStartUpLoad(false);
  //     }
  //   }
  //   handlePagination();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [offSet]);

  function handleClick(SelQue) {
    console.log(SelQue);
    setSelLink(SelQue.image_path);
    setSelData(SelQue);
    // window.scrollTo(0, 2000);
  }

  function handleCheckbox(event, row) {
    var isChecked = event?.target?.checked;
    if (isChecked === true) {
      setCheckedRows([...checkedRows, row]);
      let tempList = questionList.map((question) => {
        if (question.question_id === row.question_id) {
          return { ...question, isChecked: true };
        } else {
          return question;
        }
      });
      console.log(isEdit);
      if (isEdit) {
        if (!questionPresent.includes(row.question_id)) {
          questionAddedArray.push(row.question_id);
        }
        console.log(questionAddedArray);

        if (questionDeletedArray.includes(row.question_id)) {
          var deletedQuestionIndex = questionDeletedArray.indexOf(row.question_id);
          questionDeletedArray.splice(deletedQuestionIndex, 1);
          console.log(questionDeletedArray);
        }
      }
      // console.log(tempList);
      setQuestionList(tempList);
    }
    if (isChecked === false) {
      var tempCheckedRows = checkedRows;
      checkedRows.forEach((rowObject, rowIndex) => {
        // console.log(row.question_id, rowObject.question_id, rowIndex);
        if (row.question_id === rowObject.question_id) {
          unCheckedRowIndex = rowIndex;
        }
      });
      tempCheckedRows.splice(unCheckedRowIndex, 1);
      // console.log(tempCheckedRows);
      let tempList = questionList.map((question) => {
        return question.question_id === row.question_id ? { ...question, isChecked: false } : question;
      });
      if (isEdit) {
        if (questionAddedArray.includes(row.question_id)) {
          var remIndex = questionAddedArray.indexOf(row.question_id);
          questionAddedArray.splice(remIndex, 1);
        }
        console.log(questionAddedArray);
      }
      // console.log(tempList);
      // console.log(unCheckedRowIndex);

      setCheckedRows(tempCheckedRows);
      setQuestionList(tempList);
    }
  }
  // console.log(checkedRows);

  async function handleSaveSet() {
    setStartUpLoad(true);
    if (isEdit) {
      const editData = {
        institution_id: institution_id,
        // board_id: editQuestionSetDetails[0].board_id,
        // course_id: editQuestionSetDetails[0].course_id,
        // subject_id: editQuestionSetDetails[0].subject_id,
        added_by_user_id: editQuestionSetDetails[0].added_by_user_id,
        question_ids: questionAddedArray,
        question_set_id: +questionSetIdEdit,
      };
      console.log(editData);
      try {
        if (questionAddedArray.length > 0) {
          // const sendSetEditData = await Axios.post(
          //   `${BASE_URL}/qb/question_sets`,
          //   editData,
          //   Auth
          // );
          const sendSetEditData = await createQuestionSet(editData);
          sendSetEditData.data.data && console.log(sendSetEditData.data.data);
        }
        if (questionDeletedArray.length > 0) {
          questionDeletedArray.forEach(async (queId) => {
            // const deleteSetQuestions = await Axios.delete(
            //   `${BASE_URL}/qb/question_set_questions/${questionSetIdEdit}/${queId}`,
            //   Auth
            // );
            const deleteSetQuestions = await deleteQuestionFromSet(questionSetIdEdit, queId);
            deleteSetQuestions.data.data && console.log(deleteSetQuestions.data.data);
          });
        }
        // const getQSetBoard = await Axios.get(`${BASE_URL}/boards`, Auth);
        const getQSetBoard = await boardList();
        console.log(getQSetBoard.data.data);
        setQSetState((prevState) => {
          return {
            ...prevState,
            board: getQSetBoard.data.data,
          };
        });
        setStartUpLoad(false);
      } catch (error) {
        if (error.response && error.response.data) {
          toast.error(error.response.data.message);
          console.log(error.response.data.message);
          setStartUpLoad(false);
        } else {
          setStartUpLoad(false);
          toast.error('Server Error');
        }
      }
    } else {
      const questionIds = checkedRows.map((ques, quesIndex) => {
        return ques.question_id;
      });
      const setData = {
        institution_id: institution_id,
        // board_id: board_id,
        // course_id: course_id,
        // subject_id: subject_id,
        added_by_user_id: userId,
        question_ids: questionIds,
      };
      console.log(setData);
      try {
        // const sendSetData = await Axios.post(
        //   `${BASE_URL}/qb/question_sets`,
        //   setData,
        //   Auth
        // );
        const sendSetData = await createQuestionSet(setData);
        console.log(sendSetData.data.data.o_question_set_id);
        // const getQSetBoard = await Axios.get(`${BASE_URL}/boards`, Auth);
        const getQSetBoard = await boardList();
        console.log(getQSetBoard.data.data);
        setQSetState((prevState) => {
          return {
            ...prevState,
            board: getQSetBoard.data.data,
          };
        });
        setSetCode(sendSetData.data.data.o_question_set_id);
        toast.success('Question set saved successfully, give name and description for question set');
        setStartUpLoad(false);
      } catch (error) {
        if (error.response && error.response.data) {
          toast.error(error.response.data.message);
          console.log(error.response.data.message);
          setStartUpLoad(false);
        } else {
          setStartUpLoad(false);
          toast.error('Server Error');
        }
      }
    }
    // setQuestionView(false);
    setValues(1);
    setSetSaved(true);
  }

  function handleQueRemove(row) {
    var tempCheckedRowsRemove = checkedRows;
    checkedRows.forEach((rowObject, rowIndex) => {
      console.log(row.question_id, rowObject.question_id, rowIndex);
      if (row.question_id === rowObject.question_id) {
        unCheckedRowIndexRemove = rowIndex;
      }
    });
    tempCheckedRowsRemove.splice(unCheckedRowIndexRemove, 1);

    let tempList = questionList.map((question) => {
      return question.question_id === row.question_id ? { ...question, isChecked: false } : question;
    });
    console.log(tempList);
    console.log(unCheckedRowIndex);

    if (isEdit) {
      if (questionAddedArray.includes(row.question_id)) {
        var remIndex = questionAddedArray.indexOf(row.question_id);
        questionAddedArray.splice(remIndex, 1);
      }
      if (questionPresent.includes(row.question_id)) {
        questionDeletedArray.push(row.question_id);
      }
      console.log(questionDeletedArray);
      console.log(questionAddedArray);
    }

    setCheckedRows(tempCheckedRowsRemove);
    setQuestionList(tempList);
  }
  console.log(isEdit);
  useEffect(() => {
    if (location?.pathname === '/admin/edit-q-set' && location?.state) {
      setValues(1);
    }
    if (location?.state?.isEdit) {
      isEdit = true;
      setSetSaved(true);
      questionSetIdEdit = location?.state?.QSetCode;
    } else {
      isEdit = false;
      setSetSaved(false);
      questionSetIdEdit = null;
      setValues(0);
      setSetCode('');
    }
  }, [location]);
  useEffect(() => {
    setStartUpLoad(true);
    // isEdit = window.localStorage.getItem("isEdit");
    // questionSetIdEdit = window.localStorage.getItem("QSetCode");
    // window.localStorage.removeItem("isEdit");
    // window.localStorage.removeItem("QSetCode");
    // isEdit ? setSetSaved(true) : setSetSaved(false);
    async function fetchQSetEditDropdown() {
      try {
        // const getQSetBoard = await Axios.get(`${BASE_URL}/boards`, Auth);
        const getQSetBoard = await boardList();
        // const getQSetCourse = await Axios.get(
        //   `${BASE_URL}/institutions/${institution_id}/boards/${qSetBoard_id}/courses`,
        //   Auth
        // );
        const getQSetCourse = await fetchCourse(institution_id, qSetBoard_id);
        setQSetState((prevState) => ({
          ...prevState,
          board: getQSetBoard.data.data,
          course: getQSetCourse.data.data,
        }));
        if (qSetCourse_id) {
          // const getQSetSubject = await Axios.get(
          //   `${BASE_URL}/institutions/${institution_id}/boards/${qSetBoard_id}/courses/${qSetCourse_id}/subjects`,
          //   Auth
          // );
          const getQSetSubject = await fetchSubject(institution_id, qSetBoard_id, qSetCourse_id);
          setQSetState((prevState) => ({
            ...prevState,
            subject: getQSetSubject.data.data,
          }));
          if (qSetSubject_id) {
            // const getQSetSubSubject = await Axios.get(
            //   `${BASE_URL}/institutions/${institution_id}/boards/${qSetBoard_id}/courses/${qSetCourse_id}/subjects/${qSetSubject_id}/sub_subjects`,
            //   Auth
            // );
            const getQSetSubSubject = await fetchSubSubject(institution_id, qSetBoard_id, qSetCourse_id, qSetSubject_id);
            setQSetState((prevState) => ({
              ...prevState,
              subSubject: getQSetSubSubject.data.data,
            }));
            if (qSetSub_subject_id) {
              // const getQSetTopic = await Axios.get(
              //   `${BASE_URL}/institutions/${institution_id}/boards/${qSetBoard_id}/courses/${qSetCourse_id}/subjects/${qSetSubject_id}/sub_subjects/${qSetSub_subject_id}/topics`,
              //   Auth
              // );
              const getQSetTopic = await fetchTopic(
                institution_id,
                qSetBoard_id,
                qSetCourse_id,
                qSetSubject_id,
                qSetSub_subject_id,
              );
              console.log(getQSetTopic.data.data);
              setQSetState((prevState) => ({
                ...prevState,
                topic: getQSetTopic.data.data,
              }));
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        } else {
          toast.error('Server Error');
        }
        setStartUpLoad(false);
      }
    }
    async function fetchSelQuestions() {
      if (isEdit) {
        try {
          // const getSelQuestions = await Axios.get(
          //   `${BASE_URL}/qb/question_sets_questions/${questionSetIdEdit}`,
          //   Auth
          // );
          const getSelQuestions = await questionsForQSet(questionSetIdEdit);
          console.log(getSelQuestions.data.data);
          const getQuestionSetDetails = await Axios.get(
            `${BASE_URL}/qb/question_sets/search?question_set_id=${questionSetIdEdit}&limit=1&offset=0`,
            Auth,
          );
          if (getQuestionSetDetails.data.data) {
            console.log(getQuestionSetDetails.data.data);
            editQuestionSetDetails = getQuestionSetDetails.data.data;
            qSetBoard_id = editQuestionSetDetails[0].board_id;
            qSetCourse_id = editQuestionSetDetails[0].course_id;
            qSetSubject_id = editQuestionSetDetails[0].subject_id;
            qSetSub_subject_id = editQuestionSetDetails[0].sub_subject_id;
            // qSetTopic_id = editQuestionSetDetails[0].topic_id;
            setSetCode(questionSetIdEdit);
            setMetadata({
              ...metadata,
              display_name: editQuestionSetDetails[0].question_set_name,
              description: editQuestionSetDetails[0].description,
              board_id: editQuestionSetDetails[0].board_id,
              board: editQuestionSetDetails[0].board,
              course_id: editQuestionSetDetails[0].course_id,
              course: editQuestionSetDetails[0].course,
              subject_id: editQuestionSetDetails[0].subject_id,
              subject: editQuestionSetDetails[0].subject,
              sub_subject_id: editQuestionSetDetails[0].sub_subject_id,
              sub_subject: editQuestionSetDetails[0].sub_subject,
              topic_id: editQuestionSetDetails[0].topic_id,
              topic: editQuestionSetDetails[0].topic,
            });
            if (getSelQuestions.data.data) {
              questionPresent = getSelQuestions.data.data.map((question, queIndex) => {
                return question.question_id;
              });
              console.log(questionPresent);
              setCheckedRows(getSelQuestions.data.data);
            } else {
              toast.error('Data not available');
            }
          }
          await fetchQSetEditDropdown();
          setStartUpLoad(false);
        } catch (error) {
          if (error.response && error.response.data) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
          } else {
            toast.error('Server Error');
          }
          setStartUpLoad(false);
        }
      }
    }

    fetchSelQuestions();
    setFormData((prevState) => {
      return { ...prevState, added_by_user_id: userId };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSetMetadata(event, selInput) {
    const inputValue = event.target.value;
    console.log(inputValue);
    setMetadata({ ...metadata, [selInput]: inputValue });
  }

  async function saveSetMetadata() {
    if (
      metadata.display_name === '' ||
      metadata.description === '' ||
      metadata.board_id === '' ||
      metadata.board_id === -1 ||
      metadata.course_id === '' ||
      metadata.course_id === -1
    ) {
      toast.error('Please fill mandatory fields');
    } else {
      setStartUpLoad(true);
      console.log(metadata);
      try {
        // const metaDataSave = await Axios.put(
        //   `${BASE_URL}/qb/question_sets/${setCode}`,
        //   metadata,
        //   Auth
        // );
        const metaDataSave = await updateSetMetadata(setCode, metadata);
        console.log(metaDataSave.data);
        setStartUpLoad(false);
        toast.success('Question metadata saved successfully');
        window.location = '/admin/q-set-view';
        // window.location.reload(false);
      } catch (error) {
        if (error.response && error.response.data) {
          toast.error(error.response.data.message);
          console.log(error.response.data.message);
          setStartUpLoad(false);
        } else {
          setStartUpLoad(false);
          toast.error('Server Error');
        }
      }
    }
  }

  if (checkedRows.length > 0) {
    QSetTotalMarks = 0;
    checkedRows.forEach((row) => {
      QSetTotalMarks = QSetTotalMarks + row.max_marks;
    });
  }

  // function paginationClick(number, offSetNumber) {
  //   setPageNumber(+number);
  //   setOffSet(+offSetNumber);
  //   setStartUpLoad(true);
  // }
  console.log(questionList, selData, checkedRows);
  let mouseDown = false;
  let startX, scrollLeft;
  const slider = document.getElementsByClassName('qsetImagecardswraper');
  const startDragging = (e) => {
    mouseDown = true;
    startX = e?.pageX - slider?.[0]?.offsetLeft;
    scrollLeft = slider?.[0]?.scrollLeft;
  };

  const stopDragging = (e) => {
    mouseDown = false;
  };

  const move = (e) => {
    e.preventDefault();
    if (!mouseDown) {
      return;
    }
    const x = e?.pageX - slider?.[0]?.offsetLeft;
    const scroll = x - startX;
    slider[0].scrollLeft = scrollLeft - scroll;
  };
  const defaultVal = (e, id) => {
    console.log(e, id, e?.filter((i) => Number(i.value) === Number(id))?.[0], e && Number(id) > 0, 'default');
    if (e && Number(id) > 0) {
      return e?.filter((i) => Number(i.value) == Number(id))?.[0];
    } else {
      return null;
    }
  };

  return (
    <div>
      {startUpLoad ? (
        <div>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={startUpLoad}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      ) : null}
      <section>
        <div className="qSetCreatorHeaderDiv">
          <h1 className="qSetCreateH3">QSet Creator</h1>
          <div className="qSetCreatorSelDetailsDiv">
            {console.log(value, location?.pathname, setSaved)}
            <Box>
              <Tabs value={value} centered>
                <Tab
                  onClick={() => setValues(0)}
                  label="Select Question "
                  disabled={location?.pathname === '/admin/edit-q-set' || setSaved === true}
                />
                <Tab label="Set Details " disabled={setCode === ''} onClick={() => setValues(1)} />
              </Tabs>
            </Box>
          </div>
          <button
            className="qSetCreatorSave"
            onClick={value === 0 ? handleSaveSet : saveSetMetadata}
            disabled={(checkedRows.length === 0 || questionList?.length === 0) && isEdit === false}>
            Save Set
          </button>
        </div>
      </section>
      <div className="mainwraper">
        {location.pathname === '/admin/edit-q-sets' ? (
          <div className="view_question_main" style={{ padding: '10px' }}>
            <h4 style={{ margin: '0' }}>Questions</h4>
            <div className="qSet_ViewQuestiondiv">
              {checkedRows?.map((row, questionKey) => {
                return (
                  <div key={questionKey}>
                    <div
                      className="qset_view_ResultRowDiv"
                      // onClick={(e) => handleClick(row)}
                    >
                      <div className="qsetShortDiv" style={{ marginLeft: '10px' }}>
                        <p className="viewShortP">{row.short_description}</p>
                        <div className="viewByLastDiv">
                          {row.access_level === 'Global' && <PublicIcon className="viewPublicIcon" fontSize="small" />}
                          {row.access_level === 'Organisation' && (
                            <AccountBalanceIcon className="viewPublicIcon" fontSize="small" />
                          )}
                          {row.access_level === 'Private' && <PersonIcon className="viewPublicIcon" fontSize="small" />}
                          <p className="viewBy">By: {row.created_by}</p>
                          <p className="viewLastUpdated">
                            Last Updated:
                            <br /> {row.last_updated}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="qsetformwraper">
            <h4 className="qsetQuestionHeading">Create Questions Set</h4>
            <form>
              <div className="qsetQuestionContainer">
                <div className="qsetinput-group">
                  {console.log(formData.question_type_id, formData, 'drop')}
                  {/* <Dropdown
                    value={formData.question_type_id}
                    placeholder="Question Type"
                    label="Question Type"
                    options={state.questionsType}
                    displayName="display_name"
                    name="question_type_id"
                    idName="id"
                    onChange={handleChange}
                    req={true}>
                    {' '}
                  </Dropdown> */}
                  <div className="QSetselectContainer">
                    <SelectInput
                      // selectstyle="selectclass"
                      name="question_type_id"
                      label="Question Type"
                      placeholder="Select Question Type"
                      options={state?.questionsType}
                      value={defaultVal(state?.questionsType, formData.question_type_id)}
                      handleChange={(e) => {
                        if (e !== null) {
                          handleChange(e?.value, 'question_type_id');
                        } else {
                          handleChange('-1', 'question_type_id');
                        }
                        setValue('question_type_id', e?.value);
                        clearErrors('question_type_id');

                        // setValues('language_id', e?.value);
                        // clearErrors('language_id');
                      }}
                      isrequired
                      isClearable
                      error={!!errors?.question_type_id}
                      errorText={'Question Type is Required.'}
                      // defaultval={defaultVal(state?.language, 4)}
                    />
                  </div>
                  {/* <Dropdown
                    value={formData.board_id}
                    placeholder="Board"
                    label="Board"
                    options={state.board}
                    name="board_id"
                    displayName="board_display_name"
                    idName="board_id"
                    onChange={handleChange}
                    req={true}
                  /> */}
                  <div className="QSetselectContainer">
                    {console.log(
                      defaultVal(
                        state?.board?.map((i) => ({
                          label: i?.board_display_name,
                          value: i?.board_id?.toString(),
                        })),
                        formData?.board_id,
                      ),
                    )}
                    <SelectInput
                      // selectstyle="selectclass"
                      name="board_id"
                      label="Board"
                      placeholder="Select Board"
                      options={state?.board}
                      value={defaultVal(state?.board, formData?.board_id)}
                      handleChange={(e) => {
                        if (e !== null) {
                          handleChange(e?.value, 'board_id');
                        } else {
                          handleChange('-1', 'board_id');
                        }
                        setValue('board_id', e?.value);
                        clearErrors('board_id');
                      }}
                      isrequired
                      isClearable
                      error={!!errors?.board_id}
                      errorText={'Board is Required.'}
                      // defaultval={defaultVal(state?.language, 4)}
                    />
                  </div>
                  {/* <Dropdown
                    value={formData.course_id}
                    placeholder="Course"
                    label="Course"
                    options={state.course}
                    name="course_id"
                    displayName="display_name"
                    idName="course_id"
                    onChange={handleChange}
                    req={true}
                  /> */}
                  <div className="QSetselectContainer">
                    <SelectInput
                      // selectstyle="selectclass"
                      name="course_id"
                      label="Course"
                      placeholder="Select Course"
                      options={state?.course}
                      // value={defaultVal(state?.language, formdata?.language_id)}
                      handleChange={(e) => {
                        if (e !== null) {
                          handleChange(e?.value, 'course_id');
                        } else {
                          handleChange('-1', 'course_id');
                        }
                        setValue('course_id', e?.value);
                        clearErrors('course_id');
                      }}
                      isrequired
                      isClearable
                      error={!!errors?.course_id}
                      errorText={'Course is Required.'}
                      value={defaultVal(state?.course, formData?.course_id)}
                      // defaultval={defaultVal(state?.language, 4)}
                    />
                  </div>
                  {/* <Dropdown
                    value={formData.subject_id}
                    placeholder="Subject"
                    label="Subject"
                    options={state.subject}
                    name="subject_id"
                    displayName="display_name"
                    idName="subject_id"
                    onChange={handleChange}
                    req={true}
                  /> */}
                  <div className="QSetselectContainer">
                    <SelectInput
                      // selectstyle="selectclass"
                      name="subject_id"
                      label="Subject"
                      placeholder="Select Subject"
                      options={state?.subject}
                      // value={defaultVal(state?.language, formdata?.language_id)}
                      handleChange={(e) => {
                        if (e !== null) {
                          handleChange(e?.value, 'subject_id');
                        } else {
                          handleChange('-1', 'subject_id');
                        }
                        setValue('subject_id', e?.value);
                        clearErrors('subject_id');
                      }}
                      isrequired
                      isClearable
                      error={!!errors?.subject_id}
                      errorText={'Subject is Required.'}
                      value={defaultVal(state?.subject, formData?.subject_id)}
                      // defaultval={defaultVal(state?.language, 4)}
                    />
                  </div>
                  {/* <Dropdown
                    value={formData.sub_subject_id}
                    placeholder="Sub-Subject"
                    label="Sub-Subject"
                    options={state.subSubject}
                    name="sub_subject_id"
                    displayName="display_name"
                    idName="sub_subject_id"
                    onChange={handleChange}
                    req={false}
                  /> */}
                  <div className="QSetselectContainer">
                    <SelectInput
                      // selectstyle="selectclass"
                      name="sub_subject_id"
                      label="Sub-Subject"
                      placeholder="Select Sub-Subject"
                      options={state?.subSubject}
                      // value={defaultVal(state?.language, formdata?.language_id)}
                      handleChange={(e) => {
                        if (e !== null) {
                          handleChange(e?.value, 'sub_subject_id');
                        } else {
                          handleChange('-1', 'sub_subject_id');
                        }
                        // setValues('language_id', e?.value);
                        // clearErrors('language_id');
                      }}
                      isClearable
                      value={defaultVal(state?.subSubject, formData?.sub_subject_id)}
                      // error={!!errors?.language_id}
                      // errorText={'Language is Required.'}
                      // defaultval={defaultVal(state?.language, 4)}
                    />
                  </div>
                  {/* <Dropdown
                    value={formData.topic_1_id}
                    placeholder="Topic"
                    label="Topic"
                    options={state.topic}
                    name="topic_1_id"
                    displayName="display_name"
                    idName="topic_id"
                    onChange={handleChange}
                    req={false}
                  /> */}
                  <div className="QSetselectContainer">
                    <SelectInput
                      // selectstyle="selectclass"
                      name="topic_id"
                      label="Topic"
                      placeholder="Select Topic"
                      options={state?.topic}
                      // value={defaultVal(state?.language, formdata?.language_id)}
                      handleChange={(e) => {
                        if (e !== null) {
                          handleChange(e?.value, 'topic_1_id');
                        } else {
                          handleChange('-1', 'topic_1_id');
                        }

                        // setValues('language_id', e?.value);
                        // clearErrors('language_id');
                      }}
                      isClearable
                      value={defaultVal(state?.topic, formData?.topic_1_id)}
                      // error={!!errors?.language_id}
                      // errorText={'Language is Required.'}
                      // defaultval={defaultVal(state?.language, 4)}
                    />
                  </div>
                  {/* <Dropdown
                    value={formData.sub_topic_1_id}
                    placeholder="Sub-Topic"
                    label="Sub-Topic"
                    options={state.subTopic}
                    name="sub_topic_1_id"
                    displayName="display_name"
                    idName="sub_topic_id"
                    onChange={handleChange}
                    req={false}
                  /> */}
                  <div className="QSetselectContainer">
                    <SelectInput
                      // selectstyle="selectclass"
                      name="sub_topic_1_id"
                      label="Sub-Topic"
                      placeholder="Select Sub-Topic"
                      options={state?.subTopic}
                      // value={defaultVal(state?.language, formdata?.language_id)}
                      handleChange={(e) => {
                        if (e !== null) {
                          handleChange(e?.value, 'sub_topic_1_id');
                        } else {
                          handleChange('-1', 'sub_topic_1_id');
                        }
                        // setValues('language_id', e?.value);
                        // clearErrors('language_id');
                      }}
                      isClearable
                      value={defaultVal(state?.subTopic, formData?.sub_topic_1_id)}
                      // error={!!errors?.language_id}
                      // errorText={'Language is Required.'}
                      // defaultval={defaultVal(state?.language, 4)}
                    />
                  </div>
                  {/* <Dropdown
                    value={formData.difficulty_level}
                    placeholder="Difficulty Level"
                    label="Difficulty Level"
                    options={difficultyLevel}
                    name="difficulty_level"
                    displayName=""
                    onChange={handleChange}
                    req={false}
                  /> */}
                  <div className="QSetselectContainer">
                    <SelectInput
                      // selectstyle="selectclass"
                      name="difficulty_level"
                      label="Difficulty Level"
                      placeholder="Select Difficulty Level"
                      options={difficultyLevel?.map((i) => ({
                        label: i,
                        value: i?.toString(),
                      }))}
                      // value={defaultVal(state?.language, formdata?.language_id)}
                      handleChange={(e) => {
                        if (e !== null) {
                          handleChange(e?.value, 'difficulty_level');
                        } else {
                          handleChange('-1', 'difficulty_level');
                        }
                        // setValues('language_id', e?.value);
                        // clearErrors('language_id');
                      }}
                      value={defaultVal(
                        difficultyLevel?.map((i) => ({
                          label: i,
                          value: i?.toString(),
                        })),
                        formData?.difficulty_level,
                      )}
                      isClearable
                      // error={!!errors?.language_id}
                      // errorText={'Language is Required.'}
                      // defaultval={defaultVal(state?.language, 4)}
                    />
                  </div>
                  {/* <Dropdown
                    value={formData.access_level_id}
                    placeholder="Access Level"
                    label="Access Level"
                    options={state.accessLevel}
                    displayName="display_name"
                    name="access_level_id"
                    idName="id"
                    onChange={handleChange}
                    req={false}
                  /> */}
                  <div className="QSetselectContainer">
                    <SelectInput
                      // selectstyle="selectclass"
                      name="access_level_id"
                      label="Access Level"
                      placeholder="Select Access Level"
                      options={state?.accessLevel}
                      // value={defaultVal(state?.language, formdata?.language_id)}
                      handleChange={(e) => {
                        if (e !== null) {
                          handleChange(e?.value, 'access_level_id');
                        } else {
                          handleChange('-1', 'access_level_id');
                        }
                        // setValues('language_id', e?.value);
                        // clearErrors('language_id');
                      }}
                      value={defaultVal(state?.accessLevel, formData?.access_level_id)}
                      isClearable
                      // error={!!errors?.language_id}
                      // errorText={'Language is Required.'}
                      // defaultval={defaultVal(state?.language, 4)}
                    />
                  </div>
                  {/* <Dropdown
                    value={formData.medium_id}
                    placeholder="Medium"
                    label="Medium"
                    options={state.medium}
                    name="medium_id"
                    displayName="medium_name"
                    idName="medium_id"
                    onChange={handleChange}
                    req={false}
                  /> */}
                  <div className="QSetselectContainer">
                    <SelectInput
                      // selectstyle="selectclass"
                      name="medium_id"
                      label="Medium"
                      placeholder="Select Medium"
                      options={state?.medium}
                      // value={defaultVal(state?.language, formdata?.language_id)}
                      handleChange={(e) => {
                        if (e !== null) {
                          handleChange(e?.value, 'medium_id');
                        } else {
                          handleChange('-1', 'medium_id');
                        }
                        // setValues('language_id', e?.value);
                        // clearErrors('language_id');
                      }}
                      isClearable
                      value={defaultVal(state?.medium, formData?.medium_id)}
                      // error={!!errors?.language_id}
                      // errorText={'Language is Required.'}
                      // defaultval={defaultVal(state?.language, 4)}
                    />
                  </div>
                </div>
              </div>
              <div className="qSetContainer">
                <button type="button" className="qSetSearch" onClick={handleSubmit(Submit)}>
                  Search
                </button>
                {submitLoading ? (
                  <div className="loadingDiv">
                    <Backdrop
                      sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                      }}
                      open={submitLoading}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        )}
        {value === 0 ? (
          <>
            <div className="resultCount">
              {noData ? (
                <div className="noData" style={{ width: '100%' }}>
                  There is no available data.
                  <div>
                    <img src={ImageAsset?.qb_nodata?.default} alt="" />
                  </div>
                </div>
              ) : null}
              {questionView ? (
                <div className="compulsoryContainerQSetCreator">
                  <div className="viewQuestionsSearchDisplay">
                    <div className="viewTablePaginationQSet">
                      <div className="qsetcompulsoryHeading">
                        <Box>
                          <Tabs value={qSelectTab} centered>
                            <Tab
                              onClick={() => setqSelectTab(0)}
                              label={`Searched (${questionList?.length})`}
                              disabled={questionList?.length === 0}
                            />
                            <Tab
                              label={`Selected (${checkedRows.length})`}
                              onClick={() => setqSelectTab(1)}
                              disabled={checkedRows?.length === 0}
                            />
                          </Tabs>
                        </Box>
                      </div>
                      {qSelectTab === 0 ? (
                        <div className="viewTableContainer">
                          <div>
                            {questionList.map((row, questionKey) => (
                              <div key={questionKey}>
                                <div
                                  className={selData?.question_id === row?.question_id ? 'activecard' : 'viewResultRowDiv'}
                                  onClick={() => handleClick(row)}>
                                  <div>
                                    <Checkbox
                                      sx={{
                                        '& .MuiSvgIcon-root': { fontSize: 28 },
                                      }}
                                      onChange={(e) => handleCheckbox(e, row)}
                                      checked={row.isChecked ? row.isChecked : false}
                                    />
                                  </div>
                                  {/* <div className="qsetNumberDiv">
                                    <p>{questionKey + 1}</p>
                                  </div> */}
                                  <div className="qsetShortDiv" style={{ marginLeft: '10px' }}>
                                    <p className="viewShortP" style={{ marginTop: '10px' }}>
                                      #{row?.question_id} {row.short_description}
                                    </p>
                                    <div className="qsetviewByLastDiv">
                                      {row.access_level === 'Global' && (
                                        <PublicIcon className="viewPublicIcon" fontSize="small" style={{ margin: 0 }} />
                                      )}

                                      {row.access_level === 'Organisation' && (
                                        <AccountBalanceIcon className="viewPublicIcon" fontSize="small" style={{ margin: 0 }} />
                                      )}
                                      {row.access_level === 'Private' && (
                                        <PersonIcon className="viewPublicIcon" fontSize="small" style={{ margin: 0 }} />
                                      )}
                                      <p className="viewBy" style={{ margin: '10px 0' }}>
                                        By: {row.created_by}
                                      </p>
                                      <div>|</div>
                                      <p className="viewLastUpdated" style={{ margin: '10px 0' }}>
                                        Last Updated: {row.last_updated}
                                      </p>
                                    </div>
                                  </div>
                                  <div>
                                    {/* <input
                                      type="checkbox"
                                      className="qsetCheckbox"
                                      onChange={(e) => handleCheckbox(e, row)}
                                      checked={
                                        row.isChecked ? row.isChecked : false
                                      }
                                    /> */}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : null}

                      {/* selected Quetion list */}
                      {qSelectTab === 1 ? (
                        <div>
                          <div className="viewQuestionsSearchDisplay">
                            <div className="viewQSetTableContainer">
                              <div>
                                {checkedRows.map((row, questionKey) => {
                                  return (
                                    <div key={questionKey} onClick={() => handleClick(row)}>
                                      <div
                                        className="viewResultRowDiv"
                                        style={{ background: 'white' }}
                                        // onClick={(e) => handleClick(row)}
                                      >
                                        {/* <div className="qsetNumberDiv">
                                          <p>{questionKey + 1}</p>
                                        </div> */}
                                        <div className="qsetShortDiv" style={{ marginLeft: '10px' }}>
                                          <p className="viewShortP">
                                            #{row?.question_id} {row.short_description}
                                          </p>

                                          <p className="viewTopic" style={{ marginLeft: '0' }}>
                                            Topic: {row.topic}
                                          </p>
                                          <p className="viewTopic" style={{ marginLeft: '0' }}>
                                            Sub-Topic: {row.sub_topic}
                                          </p>
                                          <div className="qsetviewByLastDiv">
                                            {row.access_level === 'Global' && (
                                              <PublicIcon className="viewPublicIcon" fontSize="small" style={{ margin: 0 }} />
                                            )}
                                            {row.access_level === 'Organisation' && (
                                              <AccountBalanceIcon
                                                className="viewPublicIcon"
                                                fontSize="small"
                                                style={{ margin: 0 }}
                                              />
                                            )}
                                            {row.access_level === 'Private' && (
                                              <PersonIcon className="viewPublicIcon" fontSize="small" style={{ margin: 0 }} />
                                            )}
                                            <p className="viewBy">By: {row.created_by}</p>
                                            <p className="viewLastUpdated">Max Marks: {row.max_marks}</p>
                                          </div>
                                        </div>

                                        <div style={{ placeSelf: 'center' }}>
                                          <button className="setRemoveButton" onClick={(e) => handleQueRemove(row)}>
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            {/* <div className="QSetCreatorSummaryDiv">
                              <Marks checkedRows={checkedRows} />
                              <br />
                              <TopicTable checkedRows={checkedRows} />
                              <br />
                              <SubTopicTable checkedRows={checkedRows} />
                              <br />
                              <DifficultyLevel checkedRows={checkedRows} />
                              <br />
                              <Objective checkedRows={checkedRows} />
                            </div> */}
                          </div>
                        </div>
                      ) : null}
                      {/* <Pagination
                  paginationClick={paginationClick}
                  numberOfRows={numberOfQuestions}
                  offSet={offSet}
                  pageNumber={pageNumber}
                /> */}
                    </div>
                  </div>
                </div>
              ) : null}
              {selLink !== '' ? (
                <div className="questionSetTableContainer2">
                  <p className="qSetImageAbove">
                    #{selData.question_id}, Topic: {selData.topic} , Sub-topic: {selData.sub_topic}
                  </p>
                  <div className="qSetImageContainer ">
                    {selLink?.endsWith('.json') ? (
                      <Player
                        autoplay
                        loop
                        src={selLink}
                        style={{
                          width: '100%',
                          height: '450px',
                        }}
                      />
                    ) : selLink.endsWith('.webm') ? (
                      <video controls name="media" style={{ width: '100%', height: '480px' }}>
                        <source src={selLink} type="video/webm" />
                      </video>
                    ) : (
                      <img src={selLink || ImageAsset?.qb_defaultImage} alt="UpLoaded" className="qsetbacklogBigImage" />
                    )}
                  </div>
                  <div className="qSetDetailContainer">
                    {selData.response_type === 'MCQ' && (
                      <p className="qSetImageBelow">
                        Answer:{' '}
                        {selData.answer.map((object, objectKey) => {
                          return objectKey === selData.answer.length - 1 ? object.option_text : object.option_text + ', ';
                        })}
                      </p>
                    )}
                    {selData.response_type === 'Yes/No' && (
                      <p className="qSetImageBelow">Answer: {selData.answer.correct_answer}</p>
                    )}
                    {selData.response_type === '0-10' && <p className="qSetImageBelow">Answer: {selData.answer.correct_answer}</p>}
                    {selData.response_type === 'MAQ' && (
                      <p className="qSetImageBelow">
                        Answer:{' '}
                        {selData.answer.map((object, objectKey) => {
                          return objectKey === selData.answer.length - 1 ? object.option_text : object.option_text + ', ';
                        })}
                      </p>
                    )}
                    {selData.response_type === 'Matching ' && (
                      <p className="qSetImageBelow">
                        Answer:{' '}
                        {selData.answer.map((object, objectKey) => {
                          return objectKey === selData.answer.length - 1
                            ? object.left_hand_option + '-' + object.right_hand_option
                            : object.left_hand_option + '-' + object.right_hand_option + ', ';
                        })}
                      </p>
                    )}
                    {selData.response_type === 'One Word Answer' && (
                      <p className="qSetImageBelow">Answer: {selData.answer.correct_answer}</p>
                    )}
                    {selData.response_type === 'FITB' && <p className="qSetImageBelow">Answer: {selData.answer.correct_answer}</p>}
                    {selData.response_type === 'Text' && <p className="qSetImageBelow">Answer: {selData.answer.correct_answer}</p>}
                    {(selData.response_type === 'Image' ||
                      selData.response_type === 'Voice' ||
                      selData.response_type === 'Video') && (
                      <a
                        className="viewImageBelowFile"
                        href={selData.answer.answer_file_path}
                        target="_blank"
                        rel="noreferrer noopener">
                        Answer: File.
                      </a>
                    )}
                    {/* {(selData.response_type === "Image" || selData.response_type === "Voice" ||
                      selData.response_type === "Video") && (
                      <div
                        className="viewImageBelowFile"
                        onClick={(e) => downloadFile(selData.answer.answer_file_path)}
                      >
                        Answer: File.
                      </div>
                    )} */}
                    {selData.response_type === 'Files' && (
                      <a
                        className="viewImageBelowFile"
                        href={selData.answer[0].answer_file_path}
                        target="_blank"
                        rel="noreferrer noopener">
                        Answer: File.
                      </a>
                    )}
                    <p className="qSetImageBelow">Reference: {selData.reference}</p>
                    <p className="qSetImageBelow">Difficulty: {selData.difficulty_level}</p>
                    <p className="qSetImageBelow">Objective: {selData.objective}</p>
                    {/* <div className="viewAddContainer">
                      <button
                        className="migrateButton"
                        // onClick={handleEditDetails}
                      >
                        Edit Details
                      </button>
                    </div> */}
                  </div>
                </div>
              ) : null}
            </div>

            {/* {setSaved && (
              <div className="compulsoryContainerQSetCreator">
                <h4 className="compulsoryHeading">QSet</h4>
                <div className="viewQuestionsSearchDisplay">
                  <div className="viewQSetTableContainer">
                    {checkedRows.map((row, questionKey) => {
                      return (
                        <div key={questionKey}>
                          <div
                            className="viewResultRowDiv"
                            // onClick={(e) => handleClick(row)}
                          >
                            <div className="qsetNumberDiv">
                              <p>{questionKey + 1}</p>
                            </div>
                            <div className="qsetShortDiv">
                              <p className="viewShortP">
                                {row.short_description}
                              </p>
                              <div className="qsetviewByLastDiv">
                                {row.access_level === "Global" && (
                                  <PublicIcon
                                    className="viewPublicIcon"
                                    fontSize="small"
                                  />
                                )}
                                {row.access_level === "Organisation" && (
                                  <AccountBalanceIcon
                                    className="viewPublicIcon"
                                    fontSize="small"
                                  />
                                )}
                                {row.access_level === "Private" && (
                                  <PersonIcon
                                    className="viewPublicIcon"
                                    fontSize="small"
                                  />
                                )}
                                <p className="viewBy">By: {row.created_by}</p>
                                <p className="viewLastUpdated">
                                  Last Updated: {row.last_updated}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="setSummaryNameDiv">
                    <div
                      className={
                        isEdit ? "setSaveLabelPDiv" : "setSaveLabelInputDiv"
                      }
                    >
                      <label
                        className={
                          isEdit
                            ? "setSaveLabelSummaryNoStar"
                            : "setSaveLabelSummary"
                        }
                      >
                        QSet Name
                      </label>
                      {isEdit ? (
                        <p className="setSaveSummaryP">
                          {metadata.display_name}
                        </p>
                      ) : (
                        <input
                          className="setSaveInputSummary"
                          onChange={(event) =>
                            handleSetMetadata(event, "display_name")
                          }
                          value={metadata.display_name}
                        ></input>
                      )}
                    </div>
                    <div className="setSaveLabelInputDiv">
                      <label
                        className={
                          isEdit
                            ? "setSaveLabelSummaryNoStar"
                            : "setSaveLabelSummary"
                        }
                      >
                        Description
                      </label>
                      <textarea
                        className="setSaveTextareaSummary"
                        onChange={(event) =>
                          handleSetMetadata(event, "description")
                        }
                        value={metadata.description}
                      ></textarea>
                    </div>
                    <div className="setSaveLabelPDiv">
                      <label
                        className={
                          isEdit
                            ? "setSaveLabelSummaryNoStar"
                            : "setSaveLabelSummary"
                        }
                      >
                        QSet Code
                      </label>
                      <p className="setSaveSummaryP">{setCode}</p>
                    </div>
                    {isEdit ? (
                      <div className="setSaveLabelPDiv">
                        <label className="setSaveLabelSummaryNoStar">
                          Board
                        </label>
                        <p className="setSaveSummaryP">{metadata.board}</p>
                      </div>
                    ) : (
                      <div className="searchQuestionDropdown">
                        <DropdownQSet
                          label="Board"
                          options={qSetState.board}
                          name="board_id"
                          displayName="board_display_name"
                          idName="board_id"
                          onChange={handleQSetChange}
                          value={metadata.board_id}
                          req={true}
                        />
                      </div>
                    )}
                    {isEdit ? (
                      <div className="setSaveLabelPDiv">
                        <label className="setSaveLabelSummaryNoStar">
                          Course
                        </label>
                        <p className="setSaveSummaryP">{metadata.course}</p>
                      </div>
                    ) : (
                      <div className="searchQuestionDropdown">
                        <DropdownQSet
                          label="Course"
                          options={qSetState.course}
                          name="course_id"
                          displayName="display_name"
                          idName="course_id"
                          onChange={handleQSetChange}
                          value={metadata.course_id}
                          req={true}
                        />
                      </div>
                    )}
                    {isEdit ? (
                      <div className="setSaveLabelPDiv">
                        <label className="setSaveLabelSummaryNoStar">
                          Subject
                        </label>
                        <p className="setSaveSummaryP">
                          {metadata.subject
                            ? metadata.subject
                            : "Not Specified"}
                        </p>
                      </div>
                    ) : (
                      <div className="searchQuestionDropdown">
                        <DropdownQSet
                          label="Subject"
                          options={qSetState.subject}
                          name="subject_id"
                          displayName="display_name"
                          idName="subject_id"
                          onChange={handleQSetChange}
                          value={metadata.subject_id}
                          req={false}
                        />
                      </div>
                    )}
                    {isEdit ? (
                      <div className="setSaveLabelPDiv">
                        <label className="setSaveLabelSummaryNoStar">
                          Sub Subject
                        </label>
                        <p className="setSaveSummaryP">
                          {metadata.sub_subject
                            ? metadata.sub_subject
                            : "Not Specified"}
                        </p>
                      </div>
                    ) : (
                      <div className="searchQuestionDropdown">
                        <DropdownQSet
                          label="Sub-Subject"
                          options={qSetState.subSubject}
                          name="sub_subject_id"
                          displayName="display_name"
                          idName="sub_subject_id"
                          onChange={handleQSetChange}
                          value={metadata.sub_subject_id}
                          req={false}
                        />
                      </div>
                    )}
                    {isEdit ? (
                      <div className="setSaveLabelPDiv">
                        <label className="setSaveLabelSummaryNoStar">
                          Topic
                        </label>
                        <p className="setSaveSummaryP">
                          {metadata.topic ? metadata.topic : "Not Specified"}
                        </p>
                      </div>
                    ) : (
                      <div className="searchQuestionDropdown">
                        <DropdownQSet
                          label="Topic"
                          options={qSetState.topic}
                          name="topic_1_id"
                          displayName="display_name"
                          idName="topic_id"
                          onChange={handleQSetChange}
                          value={metadata.topic_id}
                          req={false}
                        />
                      </div>
                    )}
                    <div>
                      <button
                        className="summarySaveSetButton"
                        type="button"
                        onClick={saveSetMetadata}
                      >
                        Save Set
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </>
        ) : null}
        {value === 1 ? (
          <>
            {checkedRows.length > 0 && setSaved === false && (
              <div style={{ display: 'none' }} className="viewSetContainer">
                {/* <button className="viewSearchOne">Add To Set</button> */}
                <button className="viewSetSearchTwo" onClick={handleSaveSet}>
                  Save Setdcxas
                </button>
              </div>
            )}

            {setSaved ? (
              <div className="compulsoryContainerQSetCreator">
                {/* <h4 className="compulsoryHeading">QSet</h4> */}
                <div className="qsetviewQuestionsSearchDisplay">
                  <div className="setSummaryNameDiv">
                    <div className={isEdit ? 'setSaveLabelPDiv' : 'setSaveLabelInputDiv'}>
                      <label className={isEdit ? 'setSaveLabelSummary' : 'setSaveLabelSummary'}>
                        Question Set Name
                        {isEdit ? null : <em style={{ color: 'red' }}>*</em>}
                        <span>Code: {setCode}</span>
                      </label>
                      {isEdit ? (
                        <p className="setSaveSummaryP">{metadata.display_name}</p>
                      ) : (
                        <input
                          className="setSaveInputSummary"
                          placeholder="Enter a name for this question set..."
                          onChange={(event) => handleSetMetadata(event, 'display_name')}
                          value={metadata.display_name}></input>
                      )}
                    </div>
                    <div className="setSaveLabelInputDiv" style={{ marginBottom: '0' }}>
                      <label className={isEdit ? 'setSaveLabelSummaryNoStar' : 'setSaveLabelSummary'}>
                        Description
                        {isEdit ? null : <em style={{ color: 'red' }}>*</em>}
                      </label>
                      <textarea
                        className="setSaveTextareaSummary"
                        placeholder=" Describe the purpose of this question set..."
                        onChange={(event) => handleSetMetadata(event, 'description')}
                        value={metadata.description}></textarea>
                    </div>
                    {/* <div className="setSaveLabelPDiv">
                      <label
                        className={
                          isEdit
                            ? "setSaveLabelSummaryNoStar"
                            : "setSaveLabelSummary"
                        }
                      >
                        QSet Code
                      </label>
                      <p className="setSaveSummaryP">{setCode}</p>
                    </div> */}

                    <div className="setqsetgridcontainer">
                      {isEdit ? (
                        <div className="setSaveLabelPDiv">
                          <label className="setSaveLabelSummaryNoStar">
                            Board<em style={{ color: 'red' }}>*</em>
                          </label>
                          <p className="setSaveSummaryP">{metadata.board}</p>
                        </div>
                      ) : (
                        <div className="searchQuestionDropdown">
                          <DropdownQSet
                            placeholder="Board"
                            label="Board"
                            options={qSetState.board}
                            name="board_id"
                            displayName="board_display_name"
                            idName="board_id"
                            onChange={handleQSetChange}
                            value={metadata.board_id}
                            req={true}
                          />
                        </div>
                      )}
                      {isEdit ? (
                        <div className="setSaveLabelPDiv">
                          <label className="setSaveLabelSummaryNoStar">
                            Course<em style={{ color: 'red' }}>*</em>
                          </label>
                          <p className="setSaveSummaryP">{metadata.course}</p>
                        </div>
                      ) : (
                        <div className="searchQuestionDropdown">
                          <DropdownQSet
                            placeholder="Course"
                            label="Course"
                            options={qSetState.course}
                            name="course_id"
                            displayName="display_name"
                            idName="course_id"
                            onChange={handleQSetChange}
                            value={metadata.course_id}
                            req={true}
                          />
                        </div>
                      )}
                      {isEdit ? (
                        <div className="setSaveLabelPDiv">
                          <label className="setSaveLabelSummaryNoStar">Subject</label>
                          <p className="setSaveSummaryP">{metadata.subject ? metadata.subject : 'Not Specified'}</p>
                        </div>
                      ) : (
                        <div className="searchQuestionDropdown">
                          <DropdownQSet
                            placeholder="Subject"
                            label="Subject"
                            options={qSetState.subject}
                            name="subject_id"
                            displayName="display_name"
                            idName="subject_id"
                            onChange={handleQSetChange}
                            value={metadata.subject_id}
                            req={false}
                          />
                        </div>
                      )}
                      {isEdit ? (
                        <div className="setSaveLabelPDiv">
                          <label className="setSaveLabelSummaryNoStar">Sub Subject</label>
                          <p className="setSaveSummaryP">{metadata.sub_subject ? metadata.sub_subject : 'Not Specified'}</p>
                        </div>
                      ) : (
                        <div className="searchQuestionDropdown">
                          <DropdownQSet
                            placeholder="Sub-Subject"
                            label="Sub-Subject"
                            options={qSetState.subSubject}
                            name="sub_subject_id"
                            displayName="display_name"
                            idName="sub_subject_id"
                            onChange={handleQSetChange}
                            value={metadata.sub_subject_id}
                            req={false}
                          />
                        </div>
                      )}
                      {isEdit ? (
                        <div className="setSaveLabelPDiv">
                          <label className="setSaveLabelSummaryNoStar">Topic</label>
                          <p className="setSaveSummaryP">{metadata.topic ? metadata.topic : 'Not Specified'}</p>
                        </div>
                      ) : (
                        <div className="searchQuestionDropdown">
                          <DropdownQSet
                            placeholder="Topic"
                            label="Topic"
                            options={qSetState.topic}
                            name="topic_1_id"
                            displayName="display_name"
                            idName="topic_id"
                            onChange={handleQSetChange}
                            value={metadata.topic_id}
                            req={false}
                          />
                        </div>
                      )}
                    </div>
                    {/* <div>
                      <button
                        className="summarySaveSetButton"
                        type="button"
                        onClick={saveSetMetadata}
                      >
                        Save Set
                      </button>
                    </div> */}
                  </div>
                </div>
                {location?.pathname !== '/admin/edit-q-set' ? (
                  <div
                    className="qsetImagecardswraper"
                    onMouseMove={(e) => {
                      move(e);
                    }}
                    onMouseDown={(e) => {
                      startDragging(e);
                    }}
                    onMouseUp={(e) => {
                      stopDragging(e);
                    }}
                    onMouseLeave={(e) => {
                      stopDragging(e);
                    }}>
                    {checkedRows?.map((i) => (
                      <>
                        {i?.image_path?.endsWith('.json') ? (
                          <div style={{ width: '28%', flexShrink: 0 }}>
                            <Player autoplay loop src={i?.image_path} className="qsetlottiecard" />
                          </div>
                        ) : (
                          <img draggable="false" className="qsetImagecard" src={i?.image_path} alt="" />
                        )}
                      </>
                    ))}
                  </div>
                ) : null}
              </div>
            ) : null}

            {checkedRows.length > 0 && setSaved === false && <div className="compulsoryContainerQSetCreator"></div>}
            <div className="QSetCreatorSummaryDiv">
              <div className="total">
                <div className="totaldisp">
                  Total Question <span className="spanclass">{checkedRows.length}</span>
                </div>
                <div className="totaldisp">
                  Total Marks{' '}
                  <span className="spanclass">
                    {/* {checkedRows?.map((i) => i?.max_marks)} */}
                    {TotalMarks(checkedRows)}
                  </span>
                </div>
              </div>
              <div style={{ marginTop: '10px' }}>
                <TopicTable checkedRows={checkedRows} />
              </div>
              <div style={{ marginTop: '10px' }}>
                <SubTopicTable checkedRows={checkedRows} />
              </div>
              {/* <DifficultyLevel checkedRows={checkedRows} /> */}
              <div style={{ marginTop: '10px' }}>
                <Objective checkedRows={checkedRows} />
              </div>
              <div style={{ marginTop: '10px' }}>
                <DifficultyLevelGraph checkedRows={checkedRows} />
              </div>
            </div>
          </>
        ) : null}
        <ToastContainer
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
}

export default QSetCreator;
