/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './addDetails.css';
import configuration from '../../config/Config';
import s3 from '../../config/DigitalOcean';
import Axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import { BASE_URL_MIGRATE } from '../../config/Api';
import AddQuestionImage from './QuestionImage/AddQuestionImage';
import AddQuestionData from './AddQuestionData/AddQuestionData';
import addHandleReject from './AddDetailsFunctions/addHandleReject';
import fetchOtherResults from './AddDetailsFunctions/fetchOtherResult';
import fetchFirstResults from './AddDetailsFunctions/fetchFirstResults';
import addSubmit from './AddDetailsFunctions/addSubmit';
import fileUpload from './AddDetailsFunctions/fileUpload';
import changeOptionMCQ from './AddDetailsFunctions/changeOptionsMCQ';
import addFormData from './AddDetailsFunctions/addFormData';
import addMetaData from './AddDetailsFunctions/addMetaData';
import LoadingBars from '../../SubComponents/LoadingBars/LoadingBars';
import ReactLoading from 'react-loading';
import Breadcrum from '../../SubComponents/breadcrum/breadcrum';

var institution_id = 0;
var board_id = -1;
var course_id = -1;
var subject_id = -1;
var sub_subject_id = -1;
var topic_1_id = -1;
var topic_2_id = -1;
var taxonomy_id = -1;
var question_id = -1;
var FilePath;
var MAQArray = [];
let MCQCrtArray = [0, 0, 0, 0, 0, 0];
let MCQNrArray = [0, 0, 0, 0, 0, 0];
// var metaData;
// var tempData;
// var tempArray = [];
var MAQTemp;
// var tempAnswerJson;
const MCQNumber = 1;
const MAQNumber = 4;
const matchingNumber = 5;
var curatorName;
var previousPath;
var curatorId;
var questionReuseId = null;
let solutionFileChoose = false;

function AddDetails() {
  const location = useLocation();
  console.log(location, 'location');
  const [formData, setFormData] = useState({
    question_file_id: '',
    copiable_data_file_path: '',
    reference: '',
    question_type_id: -1,
    board_id: -1,
    course_id: -1,
    subject_id: -1,
    sub_subject_id: -1,
    topic_1_id: -1,
    sub_topic_1_id: -1,
    topic_2_id: -1,
    sub_topic_2_id: -1,
    difficulty_level: '',
    answer_input_type_id: -1,
    response_type_id: -1,
    evaluation_type_id: -1,
    access_level_id: -1,
    taxonomy_id: -1,
    objective_id: -1,
    max_marks: 0,
    time_required: 0,
    medium_id: -1,
    short_description: '',
    description: '',
    hint: '',
    solution_text: '',
    is_enabled: true,
    answer_json: '[]',
    solution_file: '',
    added_by_user_id: -1,
    image_path: '',
    question_reuse_id: null,
  });
  const [state, setState] = useState({
    boards: [],
    courses: [],
    subjects: [],
    mediums: [],
    subSubjects: [],
    topics: [],
    subTopics: [],
    subTopics2: [],
    questionsType: [],
    answerInput: [],
    responseType: [],
    evaluation: [],
    accessLevel: [],
    taxonomies: [],
    objectives: [],
  });

  const [matchingCount, setMatchingCount] = useState(1);
  const [backlog, setBacklog] = useState(false);
  const [viewQuestion, setViewQuestion] = useState(false);
  const [prvLink, setPrvLink] = useState('');
  const [copiableData, setCopiableData] = useState('');
  const [solutionData, setSolutionData] = useState('');
  const [responseFile, setResponseFile] = useState('');
  const [responseMatchingData, setResponseMatchingData] = useState([]);
  const [responseMCQ, setResponseMCQ] = useState([]);
  const [responseMAQData, setResponseMAQData] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [copyUpload, setCopyUpload] = useState(false);
  const [copyUploadLoading, setCopyUploadLoading] = useState(false);
  const [solFileUpload, setSolFileUpload] = useState(false);
  const [solUploadLoading, setSolUploadLoading] = useState(false);
  const [startUpLoad, setStartUpLoad] = useState(true);
  const [resImgae, setResImage] = useState(false);
  const [resVoice, setResVoice] = useState(false);
  const [resVideo, setResVideo] = useState(false);
  const [resFile, setResFile] = useState(false);
  const [resUploadLoading, setResUploadLoading] = useState(false);
  const [errorQueSub, setErrorQueSub] = useState('Error in submitting');
  const [notFilled, setNotFilled] = useState(false);
  const [curator, setCurator] = useState(false);
  const [isCurated, setIsCurated] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [tabSelected, setTabSelected] = useState('Question Print');
  const [printURL, setPrintURL] = useState('');
  const [isAlreadyCurated, setIsAlreadyCurated] = useState(false);
  const [curatorDetails, setcuratorDetails] = useState({
    preCuratorName: '',
    preCuratorId: -1,
  });

  const history = useNavigate();
  const userId = window.localStorage.getItem('userId');
  // const questionId = window.localStorage.getItem("question_id");

  console.log(institution_id, board_id, course_id, subject_id, sub_subject_id, topic_1_id, topic_2_id, taxonomy_id, question_id);

  function assigneeQuestionId(queId) {
    question_id = queId;
  }

  function assigneeMC() {
    MCQCrtArray = [];
    MCQNrArray = [];
    MAQArray = [];
  }
  useEffect(() => {
    if (previousPath === 'backlog' && responseMCQ?.length === 0) {
      MCQCrtArray = [0, 0, 0, 0, 0, 0];
      MCQNrArray = [0, 0, 0, 0, 0, 0];
    }
  }, [previousPath]);
  //function to add question copiable data file to form data
  function copyFileUpload(e) {
    fileUpload(
      e,
      copiableData,
      configuration.folderName,
      configuration.copyFileFolder,
      setFormData,
      setCopyUploadLoading,
      setCopyUpload,
      'copiable_data_file_path',
    );
  }

  function solutionFileUpload(e) {
    fileUpload(
      e,
      solutionData,
      configuration.folderName,
      configuration.solutionFolder,
      setFormData,
      setSolUploadLoading,
      setSolFileUpload,
      'solution_file',
    );
  }

  // function to add Matcing option in Response type - Matching
  function incrementMatching(e) {
    e.preventDefault();
    if (matchingCount !== 10) {
      setMatchingCount(matchingCount + 1);
    }
  }

  function assigneeBoard(data, role) {
    console.log(data, data.is_curated, 'data');
    if (role === 'Curator' || role === 'Admin') {
      console.log(role);
      setIsCurated(data.is_curated);
      setIsAlreadyCurated(data?.is_curated);
      curatorName = data.curated_by_user_name;
      curatorId = data.curated_by_user_id;
      // toast.info("This is Curated by " + curatorName);
    }
    board_id = +data.board_id || -1;
    course_id = +data.course_id || -1;
    subject_id = +data.subject_id || -1;
    sub_subject_id = +data.sub_subject_id || -1;
    topic_1_id = +data.topic_1_id || -1;
    topic_2_id = +data.topic_2_id || -1;
    taxonomy_id = +data.taxonomy_id || -1;
    if (role !== '') {
      questionReuseId = questionReuseId === null || questionReuseId === undefined ? +data.question_reuse_id : +questionReuseId;
    }
  }

  function assigneeOptions(data) {
    if (parseInt(data.response_type_id) === matchingNumber) {
      var leng = data.answer_json.length;
      setMatchingCount(leng);
      setResponseMatchingData(data.answer_json);
      console.log(123);
      let tempAnswerJson = JSON.stringify(data.answer_json);
      setFormData((prevState) => ({
        ...prevState,
        answer_json: tempAnswerJson,
      }));
    }
    if (parseInt(data.response_type_id) === MAQNumber) {
      let tempArray = data.answer_json;
      console.log(tempArray);
      MAQArray = [];
      MAQArray = tempArray.map((num) => num.option_text);
      console.log(MAQArray);
      let tempAnswerJson = JSON.stringify(data.answer_json);
      setFormData((prevState) => ({
        ...prevState,
        answer_json: tempAnswerJson,
      }));
    }
    if (parseInt(data.response_type_id) === MCQNumber) {
      let tempArray = data?.answer_json;
      console.log(tempArray);
      // MCQCrtArray = [0, 0, 0, 0, 0, 0];
      // MCQNrArray = [0, 0, 0, 0, 0, 0];
      MCQCrtArray = tempArray.map((num) => (num.is_correct_option === true ? num.option_text : 0));
      MCQNrArray = tempArray.map((num) => (num.is_near_correct_option === true ? num.option_text : 0));
      let tempAnswerJson = JSON.stringify(data.answer_json);
      setFormData((prevState) => ({
        ...prevState,
        answer_json: tempAnswerJson,
      }));
    }
  }

  function solutionChg(data) {
    if (data && data !== '') {
      solutionFileChoose = true;
    }
  }

  function assigneeValue(e, selLabel) {
    if (selLabel === 'board_id') {
      board_id = +e.target.value || -1;
    }
    if (selLabel === 'course_id') {
      course_id = +e.target.value || -1;
    }
    if (selLabel === 'subject_id') {
      subject_id = +e.target.value || -1;
    }
    if (selLabel === 'sub_subject_id') {
      sub_subject_id = +e.target.value || -1;
    }
    if (selLabel === 'topic_1_id') {
      topic_1_id = +e.target.value || -1;
    }
    if (selLabel === 'topic_2_id') {
      topic_2_id = +e.target.value || -1;
    }
    if (selLabel === 'taxonomy_id') {
      taxonomy_id = +e.target.value;
    }
  }

  async function handleQueDelete() {
    var migrateQuestionId = window.localStorage.getItem('migrateQuestionId');
    console.log(migrateQuestionId);
    if (migrateQuestionId && migrateQuestionId !== null) {
      let data = {
        Questions: [
          {
            QuestionId: migrateQuestionId,
            IsSync: true,
          },
        ],
      };

      Axios.post(`${BASE_URL_MIGRATE}/PostQuestionMigrateBulk`, qs.stringify(data))
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            console.log(err.response);
            toast.error(err.response.data.message);
          } else {
            toast.error('Server Error');
          }
        });
    }
  }
  async function handleSubmit(e) {
    addSubmit(
      e,
      formData,
      setNotFilled,
      setSubmited,
      setSubmitError,
      setSubmitLoading,
      backlog,
      userId,
      curator,
      setErrorQueSub,
      assigneeQuestionId,
      isCurated,
      handleQueDelete,
      history,
      questionReuseId,
      viewQuestion,
      question_id,
      assigneeMC,
      printURL,
      solutionFileChoose,
      setStartUpLoad,
    );
  }

  //function to store file choosen in copiable data file in local state  choose
  function handleChangeFile(e) {
    var selFile = e.target.files[0];
    if (selFile) {
      let URLData = URL.createObjectURL(selFile);
      setPrintURL(URLData);
      setCopiableData(selFile);
    } else {
      setPrintURL('');
      setCopiableData('');
    }
  }

  //function to store file choosen in solution file in local state
  function handleSoultionFile(e) {
    var selFile = e.target.files[0];
    if (selFile) {
      solutionFileChoose = true;
      setSolutionData(selFile);
    } else {
      solutionFileChoose = false;
      setSolutionData('');
    }
  }

  // function to remove particular MAQ option in Response type - Matching
  function handleRemove(e, key, selLabel) {
    e.preventDefault();
    if (selLabel === 'Matching') {
      setMatchingCount(matchingCount - 1);
      var matchingList = responseMatchingData;
      matchingList.splice(key, 1);
      matchingList = matchingList.map((obj, keyObj) => {
        return { ...obj, left_hand_option: keyObj + 1 };
      });
      setResponseMatchingData(matchingList);
      console.log(matchingList);
    }
  }

  // function to save particular MAQ option data in Response type - MAQ
  function handleChangeOption(e, selLabel, keyNum) {
    if (selLabel === 'MAQ') {
      var MAQOptionData = e.target.checked;
      if (MAQOptionData === true) {
        MAQTemp = responseMAQData;
        MAQTemp.push({ option_text: keyNum, is_correct_option: MAQOptionData });
        setResponseMAQData([...MAQTemp]);
        console.log(responseMAQData);
      }
      if (MAQOptionData === false) {
        MAQTemp = responseMAQData;
        MAQTemp = MAQTemp.filter((obj) => {
          return obj.option_text !== keyNum;
        });
        setResponseMAQData([...MAQTemp]);
        console.log(responseMAQData);
      }
    }
    if (selLabel === 'Matching') {
      var matchingOptionData = e.target.value;
      var matchingTemp = responseMatchingData;
      matchingTemp[keyNum - 1] = {
        left_hand_option: keyNum,
        right_hand_option: matchingOptionData,
      };
      setResponseMatchingData([...matchingTemp]);
      console.log(responseMatchingData);
    }
  }

  //function to hnadle response type 0-10, FITB, Text, one word answer
  function handleChangeResponse(e) {
    var res = e.target.value;
    setFormData({
      ...formData,
      answer_json: {
        // ...formData.answer_json,
        correct_answer: res,
      },
    });
  }

  // To save selected file in response to the formData
  function handleResponceFile(e, name) {
    var file = e.target.files[0];
    setResponseFile(file);
  }

  function responseUploadFile(e, type) {
    e.preventDefault();
    if (responseFile === '') {
      toast.error('Choose the File');
    }
    if (responseFile !== '') {
      setResUploadLoading(true);
      let file = responseFile;
      var userId = window.localStorage.getItem('userId');
      var newDate = new Date();
      var date =
        newDate.getDate() +
        '-' +
        (newDate.getMonth() + 1) +
        '-' +
        newDate.getFullYear() +
        '-' +
        newDate.getHours() +
        '-' +
        newDate.getMinutes() +
        '-' +
        newDate.getSeconds();
      FilePath = configuration.folderName + '/' + configuration.responseFolder + '/' + date + '-' + userId + '-' + file.name;
      var pdfFilePath = configuration.digitalOceanSpaces + FilePath;
      console.log(pdfFilePath);
      if (type !== 'Files') {
        setFormData({
          ...formData,
          answer_json: JSON.stringify({ answer_file_path: pdfFilePath }),
        });
      }
      if (type === 'Files') {
        setFormData({
          ...formData,
          answer_json: JSON.stringify([{ answer_file_path: pdfFilePath }]),
        });
      }
      const params = {
        Body: file,
        Bucket: `${configuration.bucketName}`,
        Key: FilePath,
      };
      s3.putObject(params)
        .on('build', (request) => {
          request.httpRequest.headers.Host = `${configuration.digitalOceanSpaces}`;
          request.httpRequest.headers['Content-Length'] = file.size;
          request.httpRequest.headers['Content-Type'] = file.type;
          request.httpRequest.headers['x-amz-acl'] = 'public-read';
          request.httpRequest.headers['Access-Control-Allow-Origin'] = '*';
          setResUploadLoading(false);
          if (type === 'Files') {
            setResFile(true);
          }
          if (type === 'Image') {
            setResImage(true);
          }
          if (type === 'Voice') {
            setResVoice(true);
          }
          if (type === 'Video') {
            setResVideo(true);
          }
        })
        .send((err) => {
          if (err) {
            console.log(err, 'Img error');
            toast.error(err.response.data.message);
            setResUploadLoading(false);
          }
        });
    }
  }

  // function to save MCQ data selected under response MCQ
  function handleChangeOptionMCQ(e, crtNear, selLabel, numberSel) {
    changeOptionMCQ(e, crtNear, numberSel, responseMCQ, setResponseMCQ);
  }
  console.log(responseMCQ, 'mcq');
  //function to save yes or no under response yes/no
  function handleChangeOptionYesNo(trueOrFalse) {
    setFormData({
      ...formData,
      // answer_json: JSON.stringify({
      answer_json: {
        // ...formData.answer_json,
        correct_answer: trueOrFalse,
      },
    });
  }

  function handleChange(e, selLabel) {
    console.log(selLabel);
    if (selLabel !== 'is_enabled' && selLabel !== 'is_curated') {
      const valueSele = e.target.value;
      assigneeValue(e, selLabel);
      fetchOtherResults(e, selLabel, setStartUpLoad, setState, institution_id, formData, setFormData);
      setFormData({ ...formData, [selLabel]: valueSele });
    }
    if (selLabel === 'is_enabled') {
      const valueSele = e.target.checked;
      setFormData({ ...formData, [selLabel]: valueSele });
    }
    if (selLabel === 'response_type_id') {
      const valueSele = parseInt(e.target.value);
      assigneeValue(e, selLabel);
      fetchOtherResults(e, selLabel, setStartUpLoad, setState, institution_id, formData, setFormData);
      setFormData({ ...formData, [selLabel]: valueSele });
    }
    if (selLabel === 'is_curated') {
      const valueSele = e.target.checked;
      setIsCurated(valueSele);
    }
    // setNotFilled(false);
  }

  function handleRejectChange(e) {
    var rejectReasonValue = e.target.value;
    console.log(rejectReasonValue);
    setRejectReason(rejectReasonValue);
  }

  async function handleReject() {
    addHandleReject(rejectReason, setStartUpLoad, question_id, history, assigneeMC);
  }

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      answer_json: JSON.stringify(responseMCQ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(responseMCQ)]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      answer_json: JSON.stringify(responseMAQData),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(responseMAQData)]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      answer_json: JSON.stringify(responseMatchingData),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(responseMatchingData)]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const asyncfunc = async () => {
      previousPath = window.localStorage.getItem('previousPath');
      // const previousImageLink = window.localStorage.getItem("previousImageLink");
      const previousImageLink = location?.state?.linkprv;

      const userId = window.localStorage.getItem('userId');
      // const questionIdBacklog = window.localStorage.getItem("questionId");
      // const questionIdData = window.localStorage.getItem("question_id");
      const questionIdData = location?.state?.questionId;

      // const questionFileId = window.localStorage.getItem("questionFileId");
      const questionFileId = location?.state?.questionFileId;

      const role = window.localStorage.getItem('role');
      // reuse = window.localStorage.getItem("reuse");
      questionReuseId = +window.localStorage.getItem('reuseId');

      console.log(questionFileId, previousImageLink, formData, 'image');
      if (role === 'Curator' || role === 'Admin') {
        setCurator(true);
      }

      await fetchFirstResults(setState, setStartUpLoad);

      // const fetchResults

      async function fetchMetaData() {
        addMetaData(
          formData,
          setStartUpLoad,
          previousImageLink,
          setFormData,
          institution_id,
          setState,
          assigneeBoard,
          questionIdData,
        );
      }

      async function fetchFormData() {
        addFormData(
          question_id,
          setFormData,
          questionFileId,
          assigneeBoard,
          assigneeOptions,
          institution_id,
          setStartUpLoad,
          setState,
          setPrintURL,
          solutionChg,
          setResponseMCQ,
          setcuratorDetails,
          curatorDetails,
        );
      }

      setFormData((prevState) => ({
        ...prevState,
        image_path: previousImageLink,
        added_by_user_id: userId,
      }));

      if (previousPath === 'viewQuestion') {
        setViewQuestion(true);
        setBacklog(false);
        setPrvLink(previousImageLink);
        if (questionReuseId !== null) {
          setFormData((prevState) => {
            return { ...prevState, question_reuse_id: questionReuseId };
          });
        }
        question_id = +questionIdData;
        console.log(question_id);
        fetchFormData();
      }

      if (previousPath === 'backlog') {
        setBacklog(true);
        setViewQuestion(false);
        setPrvLink(previousImageLink);
        fetchMetaData();
      }
    };
    asyncfunc();
  }, [location]);

  return (
    <div>
      {startUpLoad ? <LoadingBars /> : null}
      {isCurated && !curator ? (
        <div className="curatedWarning">
          <p className="curatedWarningP">This question is already curated by {curatorName}</p>
        </div>
      ) : null}
      <div className="queEntryDiv">
        <Breadcrum title={location?.pathname === '/admin/edit-details' ? 'Edit Question' : 'Add Question'} />
      </div>

      <form>
        <div style={{ display: 'flex' }}>
          <AddQuestionImage backlog={backlog} prvLink={prvLink} viewQuestion={viewQuestion} />
          <AddQuestionData
            setTabSelected={setTabSelected}
            tabSelected={tabSelected}
            curator={curator}
            previousPath={previousPath}
            handleChangeFile={handleChangeFile}
            copyFileUpload={copyFileUpload}
            copyUploadLoading={copyUploadLoading}
            copyUpload={copyUpload}
            handleChange={handleChange}
            formData={formData}
            state={state}
            notFilled={notFilled}
            handleChangeOptionMCQ={handleChangeOptionMCQ}
            MCQCrtArray={MCQCrtArray}
            MCQNrArray={MCQNrArray}
            handleChangeOptionYesNo={handleChangeOptionYesNo}
            handleChangeResponse={handleChangeResponse}
            handleChangeOption={handleChangeOption}
            MAQArray={MAQArray}
            matchingCount={matchingCount}
            handleRemove={handleRemove}
            responseMatchingData={responseMatchingData}
            incrementMatching={incrementMatching}
            handleResponceFile={handleResponceFile}
            responseUploadFile={responseUploadFile}
            resUploadLoading={resUploadLoading}
            resVoice={resVoice}
            resImgae={resImgae}
            resVideo={resVideo}
            resFile={resFile}
            handleSoultionFile={handleSoultionFile}
            solutionFileUpload={solutionFileUpload}
            solUploadLoading={solUploadLoading}
            solFileUpload={solFileUpload}
            handleRejectChange={handleRejectChange}
            rejectReason={rejectReason}
            handleReject={handleReject}
            // isCurated={isCurated}
            // curatorName={curatorName}
            // handleSubmit={handleSubmit}
            // curatorId={curatorId}
            // userId={userId}
            // submitLoading={submitLoading}
            // submited={submited}
            // submitError={submitError}
            // errorQueSub={errorQueSub}
            printURL={printURL}
          />
        </div>
        <div className="compulsoryContainerCheckboxSubmit">
          {curator ? (
            <div className="addDetailsCuratedDiv">
              <input
                type="checkbox"
                value={isCurated}
                checked={isCurated}
                disabled={isAlreadyCurated}
                onChange={(e) => handleChange(e, 'is_curated')}
              />{' '}
              {curatorId && isCurated ? (
                <p className="addDetailsCuratedP">
                  Curated by {curatorDetails?.preCuratorName || curatorName}{' '}
                  {isAlreadyCurated ? `(New Curator: ${localStorage.getItem('userName')})` : null}
                </p>
              ) : (
                <p className="addDetailsCuratedP">Curate</p>
              )}
            </div>
          ) : (
            <>
              {curatorId ? (
                <p className="addDetailsCuratedP" style={{ marginRight: '16px' }}>
                  Curated by {curatorDetails?.preCuratorName}{' '}
                </p>
              ) : null}
            </>
          )}
          <button type="submit" className="addSubmit" onClick={(e) => handleSubmit(e)} disabled={isAlreadyCurated && !curator}>
            Submit
          </button>
          {submitLoading ? (
            <div className="AddDetailsLoadingDiv">
              <ReactLoading type="spinningBubbles" color="#68dff0" className="reactLoadingIcon" />
              <h5 className="loadingText">Loading</h5>
            </div>
          ) : null}
          {submited ? (
            <div className="AddDetailsLoadingDiv">
              <h5 className="loadingTextData">Question is Submited</h5>
            </div>
          ) : null}
          {submitError ? (
            <div className="addDetailsLoadingDivErr">
              <h5 className="loadingTextDataErr">{errorQueSub}</h5>
            </div>
          ) : null}
        </div>
      </form>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default AddDetails;
