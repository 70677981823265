/* eslint-disable eqeqeq */
import React from 'react';
import './dropdown.css';

function Dropdown(props) {
  const displayName = props.displayName;
  const idName = props.idName;

  return (
    <div>
      <label
        className={
          (props.value === -1 || props.value === '-1') && props.notFill === true
            ? props.req
              ? 'dropdownLabelRed'
              : 'dropdownSelectNotReq'
            : props.req
              ? 'dropdownLabel'
              : 'dropdownSelectNotReq'
        }
        name={props.label}>
        {props.label}
      </label>
      <select
        className="dropdownSelect"
        style={{ color: `${props.value == -1 || props.value === '' ? 'gray' : 'black'}` }}
        onChange={(e) => props.onChange(e, props.name)}
        required={props.req}
        value={props.value}>
        <option value="-1" style={{ color: 'gray' }}>
          Select {props.placeholder}
        </option>
        {props.options.map((options, keyDrop) => {
          return displayName === '' ? (
            <option key={keyDrop} value={options[idName]} style={{ color: 'black' }}>
              {options}
            </option>
          ) : (
            <option key={keyDrop} value={options[idName]} style={{ color: 'black' }}>
              {options[displayName]}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default Dropdown;
