import React from 'react';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import SearchSuggestion from '../../../../SubComponents/SearchSuggestion/SearchSuggestion';
// import Dropdown from '../../../../SubComponents/Dropdown/Dropdown';
// import ReactLoading from 'react-loading';
// import Tooltip from '@mui/material/Tooltip';
// import Stack from '@mui/material/Stack';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './topicReportSearch.css';
import { topicReportSubmit } from '../../TopicReportFunction/topicReportSubmit';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SelectInput from '../../../../SubComponents/SelectInput/SelectInput';
import Switch from '@mui/material/Switch';
import dayjs from 'dayjs';

function TopicReportSearch({
  state,
  formData,
  handleChange,
  submitLoading,
  noData,
  achieverSelected,
  searchSuggestion,
  handleUlClose,
  handleAchieverClick,
  handleOnFocus,
  setSubmitLoading,
  setNoData,
  setNumberOfTopicReports,
  setTopicReportList,
  limit,
  offSet,
  setStartUpLoad,
  createDownloadInfo,
}) {
  const difficultyLevel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const defaultVal = (e, id) => {
    if (e && Number(id) > 0) {
      return e
        .filter((i) => Number(i.ID) === Number(id))
        ?.map((i) => ({
          label: i?.Name || i?.ClassName,
          value: i?.ID?.toString(),
        }))?.[0];
    } else {
      return null;
    }
  };
  return (
    <div className="search_container" style={{ width: '22%' }}>
      <form>
        <h4 className="topicReportHeading">Search Topic Report</h4>
        <div className="input_fields_container">
          <div className="topicReportSearchContainer">
            <div className="report_questionViewdropdownDiv">
              <label className="topicReportLabel">Created By</label>
              {/* <SearchSuggestion
                achieverSelected={achieverSelected}
                // disabled={editValue}
                handleChange={handleChange}
                // searchSuggestion={searchSuggestion}
                dropDownData={state.achieverNameList}
                // handleUlClose={handleUlClose}
                // handleUlClick={handleAchieverClick}
                // handleOnFocus={handleOnFocus}
                name="user_name"
                type="createdBy"
              /> */}
              <SelectInput
                label=""
                options={
                  state?.achieverNameList?.map((i) => ({
                    label: i?.user_name,
                    value: i?.user_id,
                  })) || []
                }
                onFocus={handleOnFocus}
                placeholder="Select Created By"
                displayName=""
                name="user_name"
                handleChange={(e) => {
                  handleAchieverClick(e, 'createdBy');
                }}
                isClearable
                value={defaultVal(state?.achieverNameList, formData?.createdBy)}
              />
            </div>
            {/* <Dropdown
              label="Medium"
              options={state.medium}
              name="medium_id"
              displayName="medium_name"
              idName="medium_id"
              value={formData.medium_id}
              onChange={handleChange}
              req={false}
            /> */}
            <div style={{ marginTop: '10px' }}>
              <SelectInput
                label="Medium"
                options={
                  state?.medium?.map((i) => ({
                    label: i?.medium_name,
                    value: i?.medium_id,
                  })) || []
                }
                placeholder="Select Medium"
                displayName=""
                name="medium_id"
                handleChange={(e) => {
                  handleChange(e, 'medium_id');
                }}
                isClearable={(e) => {
                  console.log(e, 'clear');
                }}
                value={defaultVal(state?.medium, formData?.medium_id)}
              />
            </div>
            {/* <Dropdown
              label="Questions Type"
              options={state.questionsType}
              displayName="display_name"
              name="question_type_id"
              idName="id"
              value={formData.question_type_id}
              onChange={handleChange}
              req={false}
            /> */}

            <div style={{ marginTop: '10px' }}>
              <SelectInput
                label="Question Type"
                options={
                  state?.questionsType?.map((i) => ({
                    label: i?.display_name,
                    value: i?.id,
                  })) || []
                }
                placeholder="Select Question Type"
                displayName=""
                name="question_type_id"
                handleChange={(e) => handleChange(e, 'question_type_id')}
                isClearable
                value={defaultVal(state?.questionsType, formData?.question_type_id)}
              />
            </div>

            {/* <Dropdown
              label="Board"
              options={state.board}
              name="board_id"
              displayName="board_display_name"
              idName="board_id"
              value={formData.board_id}
              onChange={handleChange}
              req={false}
            /> */}
            <div style={{ marginTop: '10px' }}>
              <SelectInput
                label="Board"
                options={
                  state?.board?.map((i) => ({
                    label: i?.board_display_name,
                    value: i?.board_id,
                  })) || []
                }
                placeholder="Select Board"
                displayName=""
                name="board_id"
                handleChange={(e) => handleChange(e, 'board_id')}
                isClearable
                value={defaultVal(state?.board, formData?.board_id)}
              />
            </div>
            {/* <Dropdown
              label="Course"
              options={state.course}
              name="course_id"
              displayName="display_name"
              idName="course_id"
              value={formData.course_id}
              onChange={handleChange}
              req={false}
            /> */}
            <div style={{ marginTop: '10px' }}>
              <SelectInput
                label="Course"
                options={
                  state?.course?.map((i) => ({
                    label: i?.display_name,
                    value: i?.course_id,
                  })) || []
                }
                placeholder="Select Course"
                displayName=""
                name="course_id"
                handleChange={(e) => handleChange(e, 'course_id')}
                isClearable
                value={defaultVal(state?.course, formData?.course_id)}
              />
            </div>
            {/* <Dropdown
              label="Subject"
              options={state.subject}
              name="subject_id"
              displayName="display_name"
              idName="subject_id"
              value={formData.subject_id}
              onChange={handleChange}
              req={false}
            /> */}
            <div style={{ marginTop: '10px' }}>
              <SelectInput
                label="Subject"
                options={
                  state?.subject?.map((i) => ({
                    label: i?.display_name,
                    value: i?.subject_id,
                  })) || []
                }
                placeholder="Select Subject"
                displayName=""
                name="subject_id"
                handleChange={(e) => handleChange(e, 'subject_id')}
                isClearable
                value={defaultVal(state?.subject, formData?.subject_id)}
              />
            </div>
            <div className="reoprt_topRepCheckDiv">
              <div className="report_topRepdropdown">
                <label className="report_topRepDropdownLabel">Sub-Subject</label>

                <Switch
                  checked={formData.sub_subject}
                  onChange={(e) => {
                    console.log(e);
                    handleChange(e, 'sub_subject');
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
            </div>
            {/* <Dropdown
              label="Topic"
              options={state.topic}
              name="topic_id"
              displayName="display_name"
              idName="topic_id"
              value={formData.topic_id}
              onChange={handleChange}
              req={false}
              displayEngName={"display_name_eng"}
            /> */}
            <div style={{ marginTop: '10px' }}>
              <SelectInput
                label="Topic"
                options={
                  state?.topic?.map((i) => ({
                    label: i?.display_name,
                    value: i?.topic_id,
                  })) || []
                }
                placeholder="Select Topic"
                displayName=""
                name="topic_id"
                handleChange={(e) => handleChange(e, 'topic_id')}
                isClearable
                value={defaultVal(state?.topic, formData?.topic_id)}
              />
            </div>
            {/* <Dropdown
              label="Taxonomy"
              options={state.taxonomies}
              displayName="display_name"
              name="taxonomy_id"
              idName="id"
              onChange={handleChange}
              value={formData.taxonomy_id}
              req={false}
            /> */}
            <div style={{ marginTop: '10px' }}>
              <SelectInput
                label="Taxonomy"
                options={
                  state?.taxonomies?.map((i) => ({
                    label: i?.display_name,
                    value: i?.id,
                  })) || []
                }
                placeholder="Select Taxonomy"
                displayName=""
                name="taxonomy_id"
                handleChange={(e) => handleChange(e, 'taxonomy_id')}
                isClearable
                value={defaultVal(state?.taxonomies, formData?.taxonomy_id)}
              />
            </div>
            {/* <Dropdown
              label="Objective"
              options={state.objectives}
              displayName="objective_code"
              name="objective_id"
              idName="objective_id"
              onChange={handleChange}
              value={formData.objective_id}
              req={false}
            /> */}
            <div style={{ marginTop: '10px' }}>
              <SelectInput
                label="Objectives"
                options={
                  state?.objectives?.map((i) => ({
                    label: i?.objective_code,
                    value: i?.objective_id,
                  })) || []
                }
                placeholder="Select Objective"
                displayName=""
                name="objective_id"
                handleChange={(e) => handleChange(e, 'objective_id')}
                isClearable
                value={defaultVal(state?.objectives, formData?.objective_id)}
              />
            </div>
            {/* <Dropdown
              label="Difficulty Level"
              options={difficultyLevel}
              name="difficulty_level"
              displayName=""
              value={formData.difficulty_level}
              onChange={handleChange}
              req={false}
            /> */}
            <div style={{ marginTop: '10px' }}>
              <SelectInput
                label="Difficulty Level"
                options={
                  difficultyLevel?.map((i) => ({
                    label: i,
                    value: i,
                  })) || []
                }
                placeholder="Select Difficulty Level"
                displayName=""
                name="difficulty_level"
                handleChange={(e) => handleChange(e, 'difficulty_level')}
                isClearable
                value={defaultVal(difficultyLevel, formData?.difficulty_level)}
              />
            </div>
            <div className="topic_ReportDropdownDiv">
              {/* <label className="topicReportLabel">From Date</label>
              <input
                className="topicReportSelect"
                type="date"
                value={formData.from_date}
                onChange={(e) => handleChange(e, "from_date")}
              ></input> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DemoItem label="From Date">
                    <DatePicker
                      slotProps={{
                        textField: {
                          size: 'small',
                          color: 'primary',
                          fullWidth: '100%',
                          background: 'white',
                        },
                        actionBar: {
                          actions: ['clear'],
                        },
                      }}
                      sx={{
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'blue',
                        },
                        backgroundColor: 'white',
                        width: '100%',
                        borderRadius: '4px',
                        border: 'none',
                      }}
                      // value={formData.from_date}
                      onChange={(e) => {
                        handleChange(e ? dayjs(e).format('YYYY-MM-DD') : '', 'from_date');
                      }}
                      // formatDate={(date) => dayjs(date).format("DD-MM-YYYY")}
                      format="DD-MM-YYYY"
                      disableFuture
                      maxDate={dayjs(formData?.to_date)}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="topic_ReportDropdownDiv">
              {/* <label className="topicReportLabel">To Date</label>
              <input
                className="topicReportSelect"
                type="date"
                value={formData.to_date}
                onChange={(e) => handleChange(e, "to_date")}
              ></input> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DemoItem label="To Date">
                    <DatePicker
                      sx={{
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'blue',
                        },
                        backgroundColor: 'white',
                        width: '100%',
                        borderRadius: '4px',
                        border: 'none',
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          color: 'primary',
                          fullWidth: '100%',
                          background: 'white',
                        },
                        actionBar: {
                          actions: ['clear'],
                        },
                      }}
                      // value={formData.to_date}
                      onChange={(e) => handleChange(e ? dayjs(e).format('YYYY-MM-DD') : '', 'to_date')}
                      format="DD-MM-YYYY"
                      disableFuture
                      minDate={dayjs(formData?.from_date)}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="topic_ReportDropdownDiv">
              <div className="topicReportLabel">Curated</div>
              <div className="topicReportRadio">
                <div>
                  <input
                    type="radio"
                    id="curated"
                    name="fav_language"
                    value={'curated'}
                    onChange={(e) => {
                      console.log(e, e.target.value, e.target.checked);
                      handleChange(e, 'is_curated');
                    }}
                  />
                  <label htmlFor="curated">Curated</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="non_curated"
                    name="fav_language"
                    value={'non_curated'}
                    onChange={(e) => {
                      console.log(e, e.target.value, e.target.checked);
                      handleChange(e, 'is_curated');
                    }}
                  />
                  <label htmlFor="non_curated">Non Curated</label>
                </div>
                <div style={{ textAlign: 'end' }}>
                  <input
                    type="radio"
                    id="all"
                    name="fav_language"
                    value={'all'}
                    defaultChecked={formData?.is_curated === 'all'}
                    onChange={(e) => {
                      console.log(e, e.target.value, e.target.checked);
                      handleChange(e, 'is_curated');
                    }}
                  />
                  <label htmlFor="all">All</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="topicReportSearchButtonContainer">
          <button
            className="topicReportSearchBtn"
            id="searchQuestions"
            type="button"
            onClick={() => {
              topicReportSubmit(
                setSubmitLoading,
                setNoData,
                formData,
                setNumberOfTopicReports,
                setTopicReportList,
                limit,
                0,
                setStartUpLoad,
              );
              createDownloadInfo(formData);
            }}>
            Search
          </button>
          {submitLoading ? (
            // <div className="topicReportloadingDiv">
            //   <ReactLoading
            //     type="spinningBubbles"
            //     color="#68dff0"
            //     className="topicRepReactLoadingIcon"
            //   />
            //   <h5 className="loadingText">Loading</h5>
            // </div>
            <div className="loadingDiv">
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={submitLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          ) : null}
          {/* {noData ? (
            <div className="topicReportloadingDiv">
              <h5 className="topicRepLoadingTextData">Data is not available</h5>
            </div>
          ) : null} */}
        </div>
      </form>
    </div>
  );
}

export default TopicReportSearch;
