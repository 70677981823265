/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import SelectInput from '../../SubComponents/SelectInput/SelectInput';
import styles from './translateQuestionPopup.module.css';
// import ImageAsset from '../../../assets/images/imageAsset';
import { toast } from 'react-toastify';
import { fetchOtherResultsTranslate } from './translateFunctions/fetchOtherResultsTranslateQues';
import { fetchDataTranslate } from './translateFunctions/fetchDataTranslate';
// import {fetchNewDropDown} from './translateFunctions/fetchNewDropDown'
import { fetchQueFormat } from '../../../api/qbMasterData';
import { populateFormData } from './translateFunctions/populateFormData';
import { translateSubmit } from './translateFunctions/translateSubmit';
import Pdf from '@mikecousins/react-pdf';
import { Player } from '@lottiefiles/react-lottie-player';
import { useForm } from 'react-hook-form';

const TranslateQuestionsPopup = (props) => {
  const [toAccept, setToAccept] = useState('');
  const [formData, setFormData] = useState({
    question_type_id: -1,
    board_id: -1,
    course_id: -1,
    subject_id: -1,
    sub_subject_id: -1,
    topic_1_id: -1,
    difficulty_level: '',
    access_level: -1,
    medium_id: -1,
    question_code: -1,
    from_date: '',
    to_date: '',
    added_by_user_id: -1,
    createdBy: '',
    createdByName: '',
    objective_id: -1,
    taxonomy_id: -1,
    translate: true,
  });
  const [state, setState] = useState({
    board: [],
    medium: [],
    course: [],
    subject: [],
    subSubject: [],
    topic: [],
  });
  const [fileInfo, setFileInfo] = useState({
    name: '',
    fullDetails: '',
    pdfFile: [],
    fileType: '',
    fileTypeId: '',
    nameSource: '',
    fullDetailsSource: '',
  });
  const [fileDetails, setFileDetails] = useState('');
  const [startUpLoad, setStartUpLoad] = useState(false);
  const [fileTypeDrop, setFileTypeDrop] = useState([]);
  const [questionCreate, setQuestionCreate] = useState(false);
  console.log(startUpLoad, questionCreate);
  let institution_id = 0;

  const {
    setValue,
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    register('fileType', { required: true });
    register('medium', { required: true });
    register('board', { required: true });
    register('course', { required: true });
    register('subject', { required: true });
    register('subSubject', { required: true });
    register('topic', { required: true });
    register('question_file_path', { required: true });
    register('question_source_path', { required: true });
  }, [register]);
  const handelChange = (e, selLabel) => {
    const valueSele = e?.target?.value || e?.value || e;

    fetchOtherResultsTranslate(e, selLabel, institution_id, setFormData, setStartUpLoad, state, setState, formData);
    setFormData({ ...formData, [selLabel]: valueSele });
  };

  const handelChangeFile = async (e, tab) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (file.size > 10e8) {
        toast.error('File should be less than 100Mb');
      } else {
        if (tab === 'file') {
          const objectUrl = URL.createObjectURL(file);
          setFileDetails(objectUrl);
          setFileInfo((prevState) => ({
            ...prevState,
            name: file.name,
            fullDetails: file,
          }));
        } else if (tab === 'source') {
          setFileInfo((prevState) => ({
            ...prevState,
            nameSource: file.name,
            fullDetailsSource: file,
          }));
        }
      }
    } else {
      setFileDetails('');
      setFileInfo((prevState) => ({
        ...prevState,
        name: '',
        fullDetails: '',
      }));
    }
  };

  // function pdfsPage(pdfdata) {
  //   var numPages = pdfdata._pdfInfo.numPages;
  //   // setNumOfPages(numPages);
  // }

  function PDFLoadFail() {
    toast.error('This is an in-valid PDF');
    setFileInfo({ ...fileInfo, fullDetails: '' });
  }

  useEffect(() => {
    setStartUpLoad(true);
    const fetchDropQueType = async () => {
      try {
        const getFileType = await fetchQueFormat();
        setFileTypeDrop(getFileType.data.data);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error('Error');
          console.log(err);
        }
      }
      setStartUpLoad(false);
    };

    fetchDropQueType();
    fetchDataTranslate(setState, state, setStartUpLoad);
    populateFormData(setFormData, setStartUpLoad, setState, institution_id, setValue);

    // eslint-disable-next-line
  }, []);

  async function onSubmit() {
    if (fileInfo.fullDetails === '') {
      toast.error('File not selected, Please choose file');
    }
    if (fileInfo.fullDetailsSource === '') {
      toast.error('Choose the Source File');
    } else {
      toast.info('Loading...');
      await translateSubmit(formData, setStartUpLoad, fileInfo, setQuestionCreate);
      toast.success('Question Added');
      props?.handleTranslatePopup();
    }
  }

  function handleChangeFileType(e) {
    console.log(e);
    const valueSele = e?.target?.value || e?.label || e;
    const idSele = e?.target?.selectedIndex || e?.value || e;

    // setFileInfo((prevState) => ({
    //   ...prevState,
    //   fileType: valueSele,
    //   fileTypeId: idSele,
    // }));
    setFileInfo({
      ...fileInfo,
      fileType: valueSele,
      fileTypeId: idSele,
      name: '',
      fullDetails: '',
      pdfFile: [],
      nameSource: '',
      fullDetailsSource: '',
    });
    setFileDetails('');
    if (valueSele === 'Text') {
      setToAccept('.txt');
    } else if (valueSele === 'PDF') {
      setToAccept('.pdf');
    } else if (valueSele === 'Image') {
      setToAccept('.png, .jpg, .jpeg, .webp, .jfif');
    } else if (valueSele === 'HTML') {
      setToAccept('.html, .htm');
    } else if (valueSele === 'PPT') {
      setToAccept('.ppt, .pptx');
    } else if (valueSele === 'Animated SVG') {
      setToAccept('Animated SVG');
    }
  }

  console.log(formData, fileInfo, fileTypeDrop, 'qwerty');

  //   function handelChange(e, selLabel) {
  //     const valueSele = e?.target?.value || e?.value;
  //     setFormData({ ...formData, [selLabel]: valueSele });
  //   }

  return (
    <div className={styles.main}>
      <div className={styles.formwraper}>
        <form>
          <div className="">
            <div style={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid gray' }}>
              <h4 className="searchQuestionHeading">Translation Questions</h4>
              {/* <img className={styles.close} src={ImageAsset?.qb_close} alt="" onClick={props?.handleTranslatePopup} /> */}
            </div>
            <div className={styles.dropdownwraper}>
              <div className="transalesearchQuestionDropdown" style={{ position: 'relative', zIndex: '110' }}>
                <SelectInput
                  label="File Type"
                  options={fileTypeDrop?.map((i) => ({ label: i?.display_name, value: i.id })) || []}
                  placeholder="Select File Type"
                  displayName=""
                  name="fileType"
                  idName="syllabus_id"
                  handleChange={(e) => {
                    if (e !== null) {
                      handleChangeFileType(e, 'file');
                      setValue('fileType', e);
                      clearErrors('fileType');
                    } else {
                      handleChangeFileType('', 'file');
                      setValue('fileType', e);
                    }
                  }}
                  //   value={fileTypeDrop
                  //     ?.map((i) => ({ label: i?.display_name, value: i.id }))
                  //     ?.filter((i) => i?.value == fileInfo?.fileTypeId)}
                  isrequired
                  error={!!errors?.fileType}
                  errorText={'File Type is Required.'}
                  isClearable
                />
              </div>
              <div className={styles.fileUploadQue} style={{ marginBottom: '7px' }}>
                <h4 className={styles.uploadH4}>Question File</h4>
                <div className={styles.fileChooseQue} style={{ position: 'relative', marginBottom: 0 }}>
                  <input
                    style={{ opacity: 0, width: '100%', zIndex: '100', position: 'relative' }}
                    type="file"
                    name="question_file_path"
                    id="question_file_path"
                    // className="fileChooseQue"
                    onChange={(e) => {
                      handelChangeFile(e, 'file');
                      setValue('question_file_path', e);
                      clearErrors('question_file_path');
                    }}
                    accept={toAccept}
                    disabled={fileInfo?.fileTypeId == '' ? true : false}
                  />
                  <div style={{ display: 'flex', position: 'absolute', top: '17%' }}>
                    <div className={`${fileInfo?.fileTypeId == '' ? 'choose_file dissabledbtn' : 'choose_file'}`}>Choose File</div>{' '}
                    <div> {fileInfo?.name || 'No File Chosen'}</div>
                  </div>
                </div>
                {!!errors?.question_file_path ? <div className="errormessage">Question Source File is Required</div> : null}
              </div>
              {fileInfo.fileType !== '' ? (
                <div>
                  {fileInfo.fileType === 'PDF' && fileDetails ? (
                    <div className="thumbnailContainerTrans">
                      {fileInfo.fullDetails !== '' ? (
                        <Pdf
                          file={fileDetails}
                          page={1}
                          // onDocumentLoadSuccess={pdfsPage}
                          onDocumentLoadFail={PDFLoadFail}
                        />
                      ) : null}
                    </div>
                  ) : null}
                  {fileInfo.fileType === 'Image' && fileDetails ? (
                    <div className="thumbnailContainerUploads">
                      {fileInfo.fullDetails !== '' ? (
                        <img src={fileDetails} alt="Thumbnail" className="imgUploadThumbnail" />
                      ) : null}
                    </div>
                  ) : null}
                  {fileInfo.fileType === 'Text' && fileDetails ? (
                    <div className="thumbnailContainerUploads">
                      {fileInfo.fullDetails !== '' ? (
                        <iframe title="TextFrame" src={fileDetails} className="uploadFileText"></iframe>
                      ) : null}
                    </div>
                  ) : null}
                  {fileInfo.fileType === 'HTML' && fileDetails ? (
                    <div className="thumbnailContainerUploads">
                      {fileInfo.fullDetails !== '' ? <iframe src={fileDetails} title="HTMLFrame"></iframe> : null}
                    </div>
                  ) : null}
                  {fileInfo.fileType === 'Animated SVG' && fileDetails ? (
                    <div className="thumbnailContainerUploads">
                      {fileInfo.fullDetails !== '' ? (
                        <Player autoplay loop src={fileDetails} style={{ width: 150, height: 150, margin: 0 }} />
                      ) : null}
                    </div>
                  ) : null}{' '}
                </div>
              ) : null}
              <div className={styles.fileUploadQue} style={{ marginBottom: '7px' }}>
                <h4 className={styles.uploadH4}>Question Source File</h4>
                <div className={styles.fileChooseQue} style={{ position: 'relative', marginBottom: 0 }}>
                  <input
                    style={{ opacity: 0, width: '100%', zIndex: '100', position: 'relative' }}
                    type="file"
                    name="question_source_path"
                    id="question_source_path"
                    // className="fileChooseQue"
                    onChange={(e) => {
                      handelChangeFile(e, 'source');
                      setValue('question_source_path', e);
                      clearErrors('question_source_path');
                    }}
                    accept={toAccept}
                    disabled={fileInfo?.fileTypeId == '' ? true : false}
                  />
                  <div style={{ display: 'flex', position: 'absolute', top: '17%' }}>
                    <div className={`${fileInfo?.fileTypeId == '' ? 'choose_file dissabledbtn' : 'choose_file'}`}>Choose File</div>{' '}
                    <div> {fileInfo?.nameSource || 'No File Chosen'}</div>
                  </div>
                </div>
                {!!errors?.question_source_path ? <div className="errormessage">Question Source File is Required</div> : null}
              </div>
              <div className="transalesearchQuestionDropdown">
                <SelectInput
                  label="Medium"
                  options={state?.medium?.map((i) => ({ label: i?.medium_name, value: i?.medium_id })) || []}
                  placeholder="Select Medium "
                  displayName=""
                  name="medium"
                  idName="Medium_id"
                  handleChange={(e) => {
                    if (e !== null) {
                      handelChange(e, 'medium_id');
                      setValue('medium', e);
                      clearErrors('medium');
                    } else {
                      handelChange('-1', 'medium_id');
                      setValue('medium', e);
                    }
                  }}
                  value={state?.medium
                    ?.map((i) => ({ label: i?.medium_name, value: i.medium_id }))
                    ?.filter((i) => i?.value === formData?.medium_id)}
                  isrequired
                  error={!!errors?.medium}
                  errorText={'Medium is Required.'}
                  isClearable
                />
              </div>
              <div className="transalesearchQuestionDropdown">
                <SelectInput
                  label="Board"
                  options={state?.board?.map((i) => ({ label: i?.board_display_name, value: i?.board_id })) || []}
                  placeholder="Select Board"
                  displayName=""
                  name="board"
                  idName="board_id"
                  handleChange={(e) => {
                    if (e !== null) {
                      handelChange(e, 'board_id');
                      setValue('board', e);
                      clearErrors('board');
                    } else {
                      handelChange('-1', 'board_id');
                      setValue('board', e);
                    }
                  }}
                  value={state?.board
                    ?.map((i) => ({ label: i?.board_display_name, value: i.board_id }))
                    ?.filter((i) => i?.value === formData?.board_id)}
                  isrequired
                  error={!!errors?.board}
                  errorText={'Board is Required.'}
                  isClearable
                />
              </div>
              <div className="transalesearchQuestionDropdown">
                <SelectInput
                  label="Course"
                  options={state?.course?.map((i) => ({ label: i?.display_name, value: i?.course_id })) || []}
                  placeholder="Select Course"
                  displayName=""
                  name="course"
                  idName="course_id"
                  handleChange={(e) => {
                    if (e !== null) {
                      handelChange(e, 'course_id');
                      setValue('course', e);
                      clearErrors('course');
                    } else {
                      handelChange('-1', 'course_id');
                      setValue('course', e);
                    }
                  }}
                  value={state?.course
                    ?.map((i) => ({ label: i?.display_name, value: i.course_id }))
                    ?.filter((i) => i?.value === formData?.course_id)}
                  isrequired
                  error={!!errors?.course}
                  errorText={'Course is Required.'}
                  isClearable
                />
              </div>
              <div className="transalesearchQuestionDropdown">
                <SelectInput
                  label="Subject"
                  options={state?.subject?.map((i) => ({ label: i?.display_name, value: i?.subject_id })) || []}
                  placeholder="Select Subject"
                  displayName=""
                  name="subject"
                  idName="subject_id"
                  handleChange={(e) => {
                    console.log(e, 'subject');
                    if (e !== null) {
                      handelChange(e, 'subject_id');
                      setValue('subject', e);
                      clearErrors('subject');
                    } else {
                      handelChange('-1', 'subject_id');
                      setValue('subject', e);
                    }
                  }}
                  value={state?.subject
                    ?.map((i) => ({ label: i?.display_name, value: i.subject_id }))
                    ?.filter((i) => i?.value === formData?.subject_id)}
                  isrequired
                  error={!!errors?.subject}
                  errorText={'Subject os Required.'}
                  isClearable
                />
              </div>
              <div className="transalesearchQuestionDropdown">
                <SelectInput
                  label="Sub-Subject"
                  options={state?.subSubject?.map((i) => ({ label: i?.display_name, value: i?.sub_subject_id })) || []}
                  placeholder="Select Sub-Subject"
                  displayName=""
                  name="subSubject"
                  idName="sub_subject_id"
                  handleChange={(e) => {
                    if (e !== null) {
                      handelChange(e, 'sub_subject_id');
                      setValue('subSubject', e);
                      clearErrors('subSubject');
                    } else {
                      handelChange('-1', 'sub_subject_id');
                      setValue('subSubject', e);
                    }
                  }}
                  value={state?.subSubject
                    ?.map((i) => ({ label: i?.display_name, value: i.sub_subject_id }))
                    ?.filter((i) => i?.value === formData?.sub_subject_id)}
                  isrequired
                  error={!!errors?.subSubject}
                  errorText={'Sub-Subject is Required.'}
                  isClearable
                />
              </div>
              <div className="transalesearchQuestionDropdown">
                <SelectInput
                  label="Topic"
                  options={state?.topic?.map((i) => ({ label: i?.display_name, value: i?.topic_id })) || []}
                  placeholder="Select Topic"
                  displayName=""
                  name="topic"
                  idName="topic_id"
                  handleChange={(e) => {
                    if (e !== null) {
                      handelChange(e, 'topic_1_id');
                      setValue('topic', e);
                      clearErrors('topic');
                    } else {
                      handelChange('-1', 'topic_1_id');
                      setValue('topic', e);
                    }
                  }}
                  value={state?.topic
                    ?.map((i) => ({ label: i?.display_name, value: i.topic_id }))
                    ?.filter((i) => i?.value === formData?.topic_1_id)}
                  isrequired
                  error={!!errors?.topic}
                  errorText={'Topic is Required. '}
                  isClearable
                />
              </div>
            </div>
          </div>
          <div className={styles.transQueContainer}>
            <button
              type="button"
              className={styles.transQueSearch}
              style={{ marginRight: '15px' }}
              onClick={handleSubmit(onSubmit)}>
              Submit
            </button>
            <button
              type="button"
              className={styles.transcancel}
              onClick={() => {
                props.handleTranslatePopup();
              }}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TranslateQuestionsPopup;
