import React, { useEffect } from 'react';
import './UploadQuestions.css';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import Axios from "axios";
// import { BASE_URL } from "../../config/Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pdf from '@mikecousins/react-pdf';
import ReactLoading from 'react-loading';
import { uploadQuestionFunction } from '../../../api/questions.js';
import { fetchQueFormat } from '../../../api/qbMasterData';
import SelectInput from '../../SubComponents/SelectInput/SelectInput.jsx';
import { Player } from '@lottiefiles/react-lottie-player';

var toAccept;

function UploadQuestions() {
  const [fileInfo, setFileInfo] = useState({
    fileType: '',
    fileTypeId: '',
    name: '',
    fullDetails: '',
    nameSource: '',
    fullDetailsSource: '',
    pdfFile: [],
  });

  const [fileDetails, setFileDetails] = useState('');
  const [fileType, setFileType] = useState([]);
  const [numOfPages, setNumOfPages] = useState(1);
  const [uploadDone, setUploadDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startUpLoad, setStartUpLoad] = useState(true);

  const history = useNavigate();

  if (fileInfo.fileType === 'Text') {
    toAccept = '.txt';
  }
  if (fileInfo.fileType === 'PDF') {
    toAccept = '.pdf';
  }
  if (fileInfo.fileType === 'Image') {
    toAccept = '.png, .jpg, .jpeg';
  }
  if (fileInfo.fileType === 'HTML') {
    toAccept = '.html, .htm';
  }
  if (fileInfo.fileType === 'PPT') {
    toAccept = '.ppt, .pptx';
  }
  if (fileInfo.fileType === 'Animated SVG') {
    toAccept = '.json';
  }
  if (fileInfo.fileType === 'webm') {
    toAccept = '.webm';
  }
  function pdfsPage(pdfdata) {
    var numPages = pdfdata._pdfInfo.numPages;
    setNumOfPages(numPages);
  }

  function PDFLoadFail() {
    toast.error('This is an in-valid PDF');
    setFileInfo({ ...fileInfo, fullDetails: '' });
  }

  // const token = window.localStorage.getItem("token");
  // const Auth = {
  //   headers: {
  //     Authorization: "Bearer " + token,
  //   },
  // };

  async function uploadFile(e) {
    e.preventDefault();
    if (fileInfo.fullDetails === '') {
      toast.error('Choose the Question File');
    }
    if (fileInfo.fullDetailsSource === '') {
      toast.error('Choose the  Source File');
    }
    if (fileInfo.fullDetails !== '' && fileInfo.fullDetailsSource !== '') {
      setLoading(true);
      var userId = window.localStorage.getItem('userId');
      window.localStorage.setItem('fileName', fileInfo.name);
      window.localStorage.setItem('fileType', fileInfo.fileType);
      const fileFullDetails = fileInfo.fullDetails;
      const fileFullDetailsSource = fileInfo.fullDetailsSource;
      let formdata = new FormData();
      formdata.append('question_file_path', fileFullDetails);
      formdata.append('source_file_path', fileFullDetailsSource);
      // Axios({
      //   url: `${BASE_URL}/qb/question_group_files?question_format_id=${fileInfo.fileTypeId}&user_id=${userId}`,
      //   mode: "no-cors",
      //   method: "POST",
      //   headers: {
      //     ...Auth.headers,
      //     "Content-Type": "multipart/form-data",
      //     Accept: "*",
      //     type: "formData",
      //     "Access-Control-Allow-Headers": "*",
      //   },
      //   data: formdata,
      // })
      await uploadQuestionFunction(fileInfo.fileTypeId, userId, formdata)
        .then((response) => {
          toast.success('Upload Successful');
          // window.localStorage.setItem(
          //   "uploadedFileData",
          //   JSON.stringify(response.data.data)
          // );
          setLoading(false);
          setUploadDone(true);
        })
        .catch((err) => {
          toast.error('Something Went Wrong');
          console.log(err);
          if (err.response && err.response.data) {
            console.log(err.response.data.message);
            toast.error(err.response.data.message);
          } else {
            toast.error('Server Error');
          }
          setLoading(false);
          setUploadDone(false);
        });
    }
  }

  function handleChange(e) {
    const valueSele = e?.target?.value || e?.label || e;
    const idSele = e?.target?.selectedIndex || e?.value || e;
    setFileInfo((prevState) => ({
      ...prevState,
      fileType: valueSele,
      fileTypeId: idSele,
      name: '',
      fullDetails: '',
      nameSource: '',
      fullDetailsSource: '',
    }));
    setFileDetails('');
  }

  const handelUpload = async (e, tab) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (file.size > 10e8) {
        toast.error('File should be less than 100Mb');
      } else {
        if (tab === 'file') {
          const objectUrl = URL.createObjectURL(file);
          setFileDetails(objectUrl);
          setFileInfo((prevState) => ({
            ...prevState,
            name: file.name,
            fullDetails: file,
          }));
        } else if (tab === 'source') {
          setFileInfo((prevState) => ({
            ...prevState,
            nameSource: file.name,
            fullDetailsSource: file,
          }));
        }
      }
    }
    //  else {
    // if (tab === 'file') {
    //   setFileDetails('');
    //   setFileInfo((prevState) => ({
    //     ...prevState,
    //     name: '',
    //     fullDetails: '',
    //   }));
    // } else if (tab === 'source') {
    //   setFileInfo((prevState) => ({
    //     ...prevState,
    //     nameSource: '',
    //     fullDetailsSource: '',
    //   }));
    // }
    // }
  };
  const fetchResults = async () => {
    try {
      setStartUpLoad(true);
      // const getFileType = await Axios.get(
      //   `${BASE_URL}/qb/question_formats`,
      //   Auth
      // );
      const getFileType = await fetchQueFormat();
      setFileType(getFileType.data.data);
      setStartUpLoad(false);
    } catch (err) {
      // console.log(err.number);
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        toast.error('Server Error');
      }
      setStartUpLoad(false);
    }
  };

  useEffect(() => {
    // const token = window.localStorage.getItem("token");
    // const Auth = {
    //   headers: {
    //     Authorization: "Bearer " + token,
    //   },
    // };

    fetchResults();
  }, []);

  if (uploadDone) {
    window.localStorage.setItem('previousPath', 'uploadQuestions');
    history(fileInfo.fullDetails !== '' ? '/admin/backlog' : '/admin/upload-questions');
    // return <Redirect to={fileInfo.fullDetails !== '' ? '/admin/backlog' : '/admin/upload-questions'}></Redirect>;
  }

  return (
    <div>
      {startUpLoad ? (
        <div>
          <ReactLoading type="spin" color="#68dff0" className="startLoadSpin" />
        </div>
      ) : null}
      <div>
        <h3 className="queH3Upload">Upload Question File</h3>
      </div>
      <div className="uploadContainer">
        <form onSubmit={(e) => uploadFile(e)}>
          <div className="uploadSubContainer">
            <div className="dropdownDivQue">
              <label className="dropdownLabelQue" name="File Type">
                File Type <span className="astrick">*</span>
              </label>
              {/* <select
                className="dropdownSelectQue"
                onChange={(e) => handleChange(e, "fileType")}
              >
                <option>Select</option>
                {fileType.map((options, keyFileType) => {
                  return (
                    <option key={keyFileType} value={options.display_name}>
                      {options.display_name}
                    </option>
                  );
                })}
              </select> */}
              <div className="dropdownSelectQue">
                <SelectInput
                  placeholder="Select File Type"
                  options={fileType?.map((i) => ({
                    label: i.display_name,
                    value: i.id,
                  }))}
                  handleChange={(e) => {
                    if (e !== null) {
                      handleChange(e, 'fileType');
                    } else {
                      handleChange('', 'fileType');
                    }
                  }}
                  isClearable
                />
              </div>
            </div>
            <div className="fileUploadQue">
              <h4 className="uploadH4">
                <TextSnippetOutlinedIcon className="uploadPDFIcon" />
                Question File
              </h4>
              <div className="fileChooseQue" style={{ position: 'relative' }}>
                <input
                  style={{ opacity: 0, width: '100%', zIndex: '1', position: 'relative' }}
                  type="file"
                  name="question_file_path"
                  id="question_file_path"
                  // className="fileChooseQue"
                  onChange={(e) => handelUpload(e, 'file')}
                  accept={toAccept}
                  disabled={fileInfo.fileType === '' ? true : false}
                />
                <div style={{ display: 'flex', position: 'absolute', top: '17%' }}>
                  <div className={`${fileInfo?.fileType === '' ? 'choose_file dissabledbtn' : 'choose_file'}`}>Choose File</div>{' '}
                  <div> {fileInfo?.name || 'No File Chosen'}</div>
                </div>
              </div>
            </div>

            {fileInfo.fileType === 'PDF' && fileDetails ? (
              <div className="thumbnailContainerUpload">
                {fileInfo.fullDetails !== '' ? (
                  <Pdf file={fileDetails} page={1} onDocumentLoadSuccess={pdfsPage} onDocumentLoadFail={PDFLoadFail} />
                ) : null}
                {fileInfo.fullDetails !== ''
                  ? Array.apply(null, {
                      length: numOfPages - 1,
                    }).map((e, keyNum) => <Pdf file={fileDetails} page={keyNum + 2} />)
                  : null}
              </div>
            ) : null}
            {fileInfo.fileType === 'Image' && fileDetails ? (
              <div className="thumbnailContainerUpload">
                {fileInfo.fullDetails !== '' ? <img src={fileDetails} alt="Thumbnail" className="imgUploadThumbnail" /> : null}
              </div>
            ) : null}
            {fileInfo.fileType === 'Text' && fileDetails ? (
              <div className="thumbnailContainerUpload">
                {fileInfo.fullDetails !== '' ? (
                  <iframe title="TextFrame" src={fileDetails} className="uploadFileText"></iframe>
                ) : null}
              </div>
            ) : null}
            {fileInfo.fileType === 'HTML' && fileDetails ? (
              <div className="thumbnailContainerUpload">
                {fileInfo.fullDetails !== '' ? <iframe src={fileDetails} title="HTMLFrame"></iframe> : null}
              </div>
            ) : null}
            {fileInfo.fileType === 'webm' && fileDetails ? (
              <div className="thumbnailContainerUpload">
                {fileInfo.fullDetails !== '' ? (
                  <video controls name="media">
                    <source src={fileDetails} type="video/webm" />
                  </video>
                ) : null}
              </div>
            ) : null}
            {fileInfo.fileType === 'Animated SVG' && fileDetails ? (
              <div className="thumbnailContainerUpload">
                {fileInfo.fullDetails !== '' ? (
                  <Player autoplay loop src={fileDetails} style={{ width: 150, height: 150, margin: 0 }} />
                ) : null}
              </div>
            ) : null}

            <div className="fileUploadQue">
              <h4 className="uploadH4" style={{ display: 'flex' }}>
                <TextSnippetOutlinedIcon className="uploadPDFIcon" />
                Question Source File <span style={{ color: 'red' }}>*</span>
              </h4>
              <div className="fileChooseQue" style={{ position: 'relative' }}>
                <input
                  style={{ opacity: 0, width: '100%', zIndex: '1', position: 'relative' }}
                  type="file"
                  name="source_file_path"
                  id="source_file_path"
                  disabled={fileInfo.fileType === '' ? true : false}
                  // className="fileChooseQue"
                  onChange={(e) => handelUpload(e, 'source')}></input>
                <div style={{ display: 'flex', position: 'absolute', top: '17%' }}>
                  <div className={`${fileInfo.fileType === '' ? 'choose_file dissabledbtn' : 'choose_file'}`}>Choose File</div>{' '}
                  <div> {fileInfo?.nameSource || 'No File Chosen'}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="addContainer">
            <button type="submit" className="addSubmit">
              Upload
            </button>
            {loading ? (
              <div className="loadingDiv">
                <ReactLoading type="spinningBubbles" color="#68dff0" className="reactLoadingIcon" />
                <h5 className="loadingText">Uploading</h5>
              </div>
            ) : null}
          </div>
        </form>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default UploadQuestions;
