import React from 'react';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import Dropdown from '../../../../SubComponents/Dropdown/Dropdown';
// import ReactLoading from 'react-loading';
import './qSetReportSearch.css';
import { qSetReportSubmit } from '../../QSetReportFunction/qSetReportSubmit';
import SelectInput from '../../../../SubComponents/SelectInput/SelectInput';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

function QSetReportSearch({
  state,
  formData,
  handleChange,
  submitLoading,
  noData,
  setSubmitLoading,
  setNoData,
  setNumberOfQSetReports,
  setQSetReportList,
  limit,
  offSet,
  setStartUpLoad,
  createDownloadInfo,
  setPageNumber,
}) {
  const defaultVal = (e, id) => {
    if (e && Number(id) > 0) {
      return e
        .filter((i) => Number(i.ID) === Number(id))
        ?.map((i) => ({
          label: i?.Name || i?.ClassName,
          value: i?.ID?.toString(),
        }))?.[0];
    } else {
      return null;
    }
  };
  return (
    <div className="qset_search_container" style={{ width: '22%' }}>
      <form>
        <h4 className="qSetReportHeading">Search QSet Report</h4>
        <div className="qset_imput_wraper">
          <div className="qSetReportSearchContainer">
            {/* <Dropdown
              label="Medium"
              options={state.medium}
              name="medium_id"
              displayName="medium_name"
              idName="medium_id"
              value={formData.medium_id}
              onChange={handleChange}
              req={false}
            /> */}
            <div style={{ marginTop: '10px' }}>
              <SelectInput
                label="Medium"
                options={
                  state?.medium?.map((i) => ({
                    label: i?.medium_name,
                    value: i?.medium_id,
                  })) || []
                }
                placeholder="Select Medium"
                displayName=""
                name="medium_id"
                handleChange={(e) => handleChange(e, 'medium_id')}
                isClearable
                value={defaultVal(state?.medium, formData?.medium_id)}
              />
            </div>
            {/* <Dropdown
              label="Questions Type"
              options={state.questionsType}
              displayName="display_name"
              name="question_type_id"
              idName="id"
              value={formData.question_type_id}
              onChange={handleChange}
              req={false}
            >
              {" "}
            </Dropdown> */}
            <div style={{ marginTop: '10px' }}>
              <SelectInput
                label="Question Type"
                options={
                  state?.questionsType?.map((i) => ({
                    label: i?.display_name,
                    value: i?.id,
                  })) || []
                }
                placeholder="Select Question Type"
                displayName=""
                name="question_type_id"
                handleChange={(e) => handleChange(e, 'question_type_id')}
                isClearable
                value={defaultVal(state?.questionsType, formData?.question_type_id)}
              />
            </div>
            {/* <Dropdown
              label="Board"
              options={state.board}
              name="board_id"
              displayName="board_display_name"
              idName="board_id"
              value={formData.board_id}
              onChange={handleChange}
              req={false}
            /> */}
            <div style={{ marginTop: '10px' }}>
              <SelectInput
                label="Board"
                options={
                  state?.board?.map((i) => ({
                    label: i?.board_display_name,
                    value: i?.board_id,
                  })) || []
                }
                placeholder="Select Board"
                displayName=""
                name="board_id"
                handleChange={(e) => handleChange(e, 'board_id')}
                isClearable
                value={defaultVal(state?.board, formData?.board_id)}
              />
            </div>
            {/* <Dropdown
              label="Course"
              options={state.course}
              name="course_id"
              displayName="display_name"
              idName="course_id"
              value={formData.course_id}
              onChange={handleChange}
              req={false}
            /> */}
            <div style={{ marginTop: '10px' }}>
              <SelectInput
                label="Course"
                options={
                  state?.course?.map((i) => ({
                    label: i?.display_name,
                    value: i?.course_id,
                  })) || []
                }
                placeholder="Select Course"
                displayName=""
                name="course_id"
                handleChange={(e) => handleChange(e, 'course_id')}
                isClearable
                value={defaultVal(state?.course, formData?.course_id)}
              />
            </div>
            {/* <Dropdown
              label="Subject"
              options={state.subject}
              name="subject_id"
              displayName="display_name"
              idName="subject_id"
              value={formData.subject_id}
              onChange={handleChange}
              req={false}
            /> */}
            <div style={{ marginTop: '10px' }}>
              <SelectInput
                label="Subject"
                options={
                  state?.subject?.map((i) => ({
                    label: i?.display_name,
                    value: i?.subject_id,
                  })) || []
                }
                placeholder="Select Subject"
                displayName=""
                name="subject_id"
                handleChange={(e) => handleChange(e, 'subject_id')}
                isClearable
                value={defaultVal(state?.subject, formData?.subject_id)}
              />
            </div>
            {/* <Dropdown
              label="Sub-Subject"
              options={state.subSubject}
              name="sub_subject_id"
              displayName="display_name"
              idName="sub_subject_id"
              value={formData.sub_subject_id}
              onChange={handleChange}
              req={false}
              displayEngName={"display_name_eng"}
            /> */}
            <div style={{ marginTop: '10px' }}>
              <SelectInput
                label="Sub-Subject"
                options={
                  state?.subSubject?.map((i) => ({
                    label: i?.display_name,
                    value: i?.sub_subject_id,
                  })) || []
                }
                placeholder="Select Sub-Subject"
                name="sub_subject_id"
                handleChange={(e) => handleChange(e, 'sub_subject_id')}
                isClearable
                value={defaultVal(state?.subSubject, formData?.sub_subject_id)}
              />
            </div>

            {/* <Dropdown
              label="Topic"
              options={state.topic}
              name="topic_id"
              displayName="display_name"
              idName="topic_id"
              value={formData.topic_id}
              onChange={handleChange}
              req={false}
              displayEngName={"display_name_eng"}
            /> */}
            <div style={{ marginTop: '10px' }}>
              <SelectInput
                label="Topic"
                options={
                  state?.topic?.map((i) => ({
                    label: i?.display_name,
                    value: i?.topic_id,
                  })) || []
                }
                placeholder="Select Topic"
                name="topic_id"
                handleChange={(e) => handleChange(e, 'topic_id')}
                isClearable
                value={defaultVal(state?.topic, formData?.topic_id)}
              />
            </div>
            <div className="topic_ReportDropdownDiv">
              {/* <label className="qSetReportLabel">From Date</label>
              <input
                className="qSetReportSelect"
                type="date"
                value={formData.from_date}
                onChange={(e) => handleChange(e, "from_date")}
              ></input> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DemoItem label="From Date">
                    <DatePicker
                      slotProps={{
                        textField: {
                          size: 'small',
                          color: 'primary',
                          fullWidth: '100%',
                          background: 'white',
                        },
                        actionBar: {
                          actions: ['clear'],
                        },
                      }}
                      sx={{
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'blue',
                        },
                        backgroundColor: 'white',
                        width: '100%',
                        borderRadius: '4px',
                        border: 'none',
                      }}
                      // value={formData.from_date}
                      onChange={(e) => {
                        handleChange(e ? dayjs(e).format('YYYY-MM-DD') : '', 'from_date');
                      }}
                      // formatDate={(date) => dayjs(date).format("DD-MM-YYYY")}
                      format="DD-MM-YYYY"
                      disableFuture
                      maxDate={dayjs(formData?.to_date)}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="topic_ReportDropdownDiv">
              {/* <label className="qSetReportLabel">To Date</label>
              <input
                className="qSetReportSelect"
                type="date"
                value={formData.to_date}
                onChange={(e) => handleChange(e, "to_date")}
              ></input> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DemoItem label="To Date">
                    <DatePicker
                      sx={{
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'blue',
                        },
                        backgroundColor: 'white',
                        width: '100%',
                        borderRadius: '4px',
                        border: 'none',
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          color: 'primary',
                          fullWidth: '100%',
                          background: 'white',
                        },
                        actionBar: {
                          actions: ['clear'],
                        },
                      }}
                      // value={formData.to_date}
                      onChange={(e) => handleChange(e ? dayjs(e).format('YYYY-MM-DD') : '', 'to_date')}
                      format="DD-MM-YYYY"
                      disableFuture
                      minDate={dayjs(formData?.from_date)}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
        </div>
        <div className="qSetReportSearchButtonContainer">
          <button
            className="qSetReportSearchBtn"
            id="searchQuestions"
            type="button"
            onClick={() => {
              qSetReportSubmit(
                setSubmitLoading,
                setNoData,
                formData,
                setNumberOfQSetReports,
                setQSetReportList,
                10,
                0,
                setStartUpLoad,
              );
              limit = 10;
              offSet = 0;
              setPageNumber(1);
              createDownloadInfo(formData);
            }}>
            Search
          </button>
          {submitLoading ? (
            <div className="qSetReportloadingDiv">
              {/* <ReactLoading
                type="spinningBubbles"
                color="#68dff0"
                className="qSetRepReactLoadingIcon"
              />
              <h5 className="loadingText">Loading</h5> */}
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={submitLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          ) : null}
          {/* {noData ? (
            <div className="qSetReportloadingDiv">
              <h5 className="qSetRepLoadingTextData">Data is not available</h5>
            </div>
          ) : null} */}
        </div>
      </form>
    </div>
  );
}

export default QSetReportSearch;
